<div class="trailer-dialog">
  <div class="trailer-dialog__header">
    <div class="trailer-dialog__title">{{ title }}</div>
    <div class="trailer-dialog__action-icons">
      <i
        class="icon-edit trailer-dialog__icon"
        *ngIf="!isCreationMode"
        [class.icon-edit--active]="isEditMode"
        (click)="enableEdit()"
      ></i>
      <i class="icon-close trailer-dialog__icon" (click)="closePopup()"></i>
    </div>
  </div>
  <form class="trailer-dialog__form form" [formGroup]="form">
    <app-trailer-upload-image
      [mainImage]="mainImage"
      [images]="images"
      [isCreationMode]="isCreationMode"
      [isEditMode]="!isEditMode"
      (updateMainImage)="updateMainImage($event)"
      (deleteImages)="addDeleteImagesToList($event)"
      (updateFiles)="updateTrailerFiles($event)"
      (changeDetailsView)="changeImageDetails($event)"
    ></app-trailer-upload-image>
    <div class="trailer-dialog__list" [ngStyle]="{ display: openDetails ? 'none' : 'grid' }">
      <div class="trailer-dialog__item" *ngFor="let item of trailerProperties">
        <p class="trailer-dialog__item-label">
          {{ item.title }}
          <span class="trailer-dialog__item-value-required" *ngIf="item.required">*</span>
        </p>
        <p class="trailer-dialog__item-value" [ngSwitch]="item.type" *ngIf="!isEditMode">
          <span *ngSwitchCase="'date'">
            {{ item.value | date: 'M/d/YYYY' }}
          </span>
          <span *ngSwitchCase="'dropdown'">
            {{ getTrailerTypeValue(item.value) }}
          </span>
          <span *ngSwitchDefault>
            {{ item?.value }}
          </span>
        </p>
        <div
          class="trailer-dialog__form-group"
          [ngStyle]="{ display: isEditMode ? 'block' : 'none' }"
          [ngSwitch]="item.type"
        >
          <textarea
            rows="4"
            class="form__input-control trailer-dialog__input"
            *ngSwitchCase="'textarea'"
            [formControlName]="item.property"
            [innerHTML]="item.value"
          ></textarea>
          <input
            type="text"
            [appOnlyNumber]="true"
            class="form__input-control trailer-dialog__input"
            [formControlName]="item.property"
            *ngSwitchCase="'number'"
          />
          <input
            type="string"
            class="form__input-control trailer-dialog__input"
            [formControlName]="item.property"
            *ngSwitchCase="'string'"
          />
          <select
            class="input-select trailer-dialog__input"
            [formControlName]="item.property"
            *ngSwitchCase="'dropdown'"
            (change)="changeDropdownItem($event, item.property)"
          >
            <option *ngFor="let itemName of item.dropdown" [value]="itemName">
              {{ getTrailerTypeValue(itemName) }}
            </option>
          </select>
          <ngx-datepicker
            *ngSwitchCase="'date'"
            [formControlName]="item.property"
            [options]="options"
          ></ngx-datepicker>
        </div>
      </div>
    </div>
    <div class="trailer-dialog__button" *ngIf="isEditMode">
      <button
        class="button button--main"
        (click)="save()"
        [disabled]="form.invalid"
        *ngIf="!openDetails"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
