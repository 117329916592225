import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export const pagerSteps = [8, 16, 24, 32, 40, 48, 56, 64, 72, 80];

export class PagerModel {
  skip!: number;
  take!: number;
}

@Component({
  selector: 'app-paginate',
  template: `
    <div class="pager-wrapper">
      <ul>
        <li (click)="changePage(getActivePage())">
          <ng-container>
            {{ getActiveNumber(pageList) === 1 ? 1 : getActiveNumber(pageList) * take - take }} -
            {{
              take * getActiveNumber(pageList) > totalItems
                ? totalItems
                : take * getActiveNumber(pageList)
            }}
            of
            {{ totalItems }}
          </ng-container>
        </li>
      </ul>

      <img
        src="/assets/images/arrow-left.svg"
        alt="Left Icon"
        class="icon-arrow icon-arrow--left"
        (click)="prevPage()"
        [class.disabled]="prevDisabled"
      />

      <img
        src="/assets/images/arrow-right.svg"
        alt="Right Icon"
        class="icon-arrow icon-arrow--right"
        (click)="nextPage()"
        [class.disabled]="nextDisabled"
      />
    </div>
  `,
  styleUrls: ['pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  pages = 0;
  pageList: { active: boolean; id: number }[] = [];
  pageListFull: { active: boolean; id: number }[] = [];

  get prevDisabled(): boolean {
    return this.pageList.find((x) => x.active)?.id === 1;
  }
  get nextDisabled(): boolean {
    return this.pageList.find((x) => x.active)?.id === this.pages;
  }

  @Input() take!: number;
  @Input() totalItems!: number;
  @Input() pager!: PagerModel;

  @Output() pageChange = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes['take'] && changes['take'].currentValue !== changes['take'].previousValue) ||
      (changes['totalItems'] &&
        changes['totalItems'].currentValue !== changes['totalItems'].previousValue)
    ) {
      this.pages = Math.ceil(this.totalItems / this.take);

      if (!this.pages) {
        return;
      }

      this.pageListFull = Array(this.pages)
        .fill(0)
        .map((el, i) => ({ id: i + 1, active: false }));

      this.recalculatePagesList();
    }
  }

  getActivePage(): { active: boolean; id: number } {
    return this.pageList[this.pageList.findIndex((x) => x.active) + 1];
  }

  getActiveNumber(list: { active: boolean; id: number }[]): number {
    return list.find((item) => item.active === true)?.id || 1;
  }

  changePage(page: { active: boolean; id: number } | undefined): void {
    if (page) {
      this.pageChange.emit(page.id - 1);

      this.pageList.forEach((item) => (item.active = false));
      page.active = true;

      this.recalculatePagesList();
    }
  }

  prevPage(): void {
    if (this.prevDisabled) {
      return;
    }

    this.changePage(this.pageList[this.pageList.findIndex((x) => x.active) - 1]);
  }

  nextPage(): void {
    if (this.nextDisabled) {
      return;
    }

    this.changePage(this.pageList[this.pageList.findIndex((x) => x.active) + 1]);
  }

  private recalculatePagesList(): void {
    const activeId = this.pageList.find((x) => x.active)?.id || 1;

    let chunk = this.pageListFull.filter(
      (el) =>
        el.id === activeId ||
        el.id === (activeId - 1 >= 1 ? activeId - 1 : activeId + 2) ||
        el.id === (activeId + 1 <= this.pages ? activeId + 1 : activeId - 2)
    );
    if (!chunk.find((x) => x.id === activeId)) {
      chunk = [];
      for (let index = 0; index < this.pages; index++) {
        chunk.push({ id: index + 1, active: false });
      }
      chunk[0].active = true;
    } else {
      const index = chunk.findIndex((x) => x.id === activeId);
      chunk[index].active = true;
    }

    if (!chunk.find((x) => x.id === 1)) {
      if (!chunk.find((x) => x.id === 2)) {
        chunk.unshift({ id: 0, active: false });
      }

      chunk.unshift({ id: 1, active: false });
    }

    if (!chunk.find((x) => x.id === this.pages)) {
      if (!chunk.find((x) => x.id === this.pages - 1)) {
        chunk.push({ id: 0, active: false });
      }

      chunk.push({ id: this.pages, active: false });
    }

    this.pageList = chunk;
  }
}
