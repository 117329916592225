<div class="note-section">
  <div class="note-section__header">
    <p class="note-section__text note-section__text--bold">Terms and Conditions</p>
    <p class="note-section__text note-section__text--link" (click)="toggleTermsAndCondition()">
      {{ showTermsAndCondition ? 'Hide' : 'Show' }}
    </p>
  </div>
  <div *ngIf="showTermsAndCondition">
    <p class="note-section__text">1. AVAILABILITY</p>
    <p class="note-section__text">
      Delivery and stock are subject to confirmation at the time of order placement.
    </p>
    <p class="note-section__text">2. INSURANCE</p>
    <p class="note-section__text">
      During the term of rental, the Customer shall maintain insurance against loss, theft,
      destruction or damage of or to the Rental Equipment in an amount not less than the aggregate
      stipulated value of the Rental Equipment. The Customer shall be responsible for soliciting
      from EXCEL any and all Rental information required to obtain said insurance.
    </p>
    <p class="note-section__text">3. TRANSPORTATION</p>
    <p class="note-section__text">
      In order to ensure safe and efficient deliveries and pick-ups, EXCEL asks for the following
      conditions to be met in order to avoid any extra charges being added to your original
      transportation quotation. This notice will serve to detail the Scope of Work required by EXCEL
      prior to our arrival on site.
    </p>
    <br />
    <p class="note-section__text note-section__text--bold">The Customer is required to:</p>
    <p class="note-section__text">
      For 12x60 skidded trailer provide 120' of clearance for trucker to access and egress from
      building footprint; <br />
      Provide level, graded site to within 3" on 60"; <br />
      Removal of obstructions that could hinder delivery and/or pick-up (trees, automobiles, power
      lines, fencing, barriers, propane tanks, etc.); <br />
      Fire extinguisher must be located in unit and supplied by
      Customer;Heating/plumbing/electrical/data hook ups provided by others; <br />
      Notify EXCEL of any special requirements/orientations for site access prior to date of
      transportation; <br />
      Coordinate transportation with EXCEL a minimum of 3 business days prior to expected delivery
      and pick-up dates; <br />
      Disconnect all utilities and remove all Customer property from the unit(s) prior to pick-up;
    </p>
    <p class="note-section__text note-section__text--bold">Please note:</p>
    <p class="note-section__text">
      The transportation price is based on 1 hour to load and 1 hour to unload. It does not cover
      any struck & tow or waiting time. Any additional time will be charged out at $165.00/hr;<br />This
      price is subject to a fuel surcharge;<br /><br />In the event that extra time or equipment is
      required for delivery or pick-up, EXCEL will invoice directly to the Customer any and all
      charges extended to them by EXCEL transporters. Extra time will be billed as per the hourly
      rate specified in the contract.
    </p>
    <br />
    <p class="note-section__text">4. MAINTANANCE, CLEANING AND WINTERIZING</p>
    <p class="note-section__text">
      Maintenance of the equipment is by the Customer and is to be insured while on rent. The
      equipment shall be returned in a clean, sanitary condition. This notice will serve to detail
      the cleaning and winterizing Scope of Work required by EXCEL.
    </p>
    <br />
    <p class="note-section__text">
      The Customer is required to: <br />
      Thoroughly clean the units, including all furniture; <br />
      Ensure all folding tables, desks, chairs, etc. are stowed in a manner to avoid damage during
      transport (i.e. tables and desks to be placed on the floor with top-down); <br />
      Remove all posters, graffiti and other writing; <br />
      Repair damages sustained during the lease period to original condition; <br />
      Replace all missing items; <br />
      Drain water from water storage tank; <br />
      Drain and blow out all water lines, water heaters, pumps and water using equipment; Drain the
      DWN (drainage waste and vent) system including toilet tanks, bowls, urinals and traps and fill
      with non-toxic anti-freeze; <br />
      If unit(s) is equipped with washing machines, add approximately 1/3 gallon of non-toxic
      anti-freeze. <br /><br />
      If the equipment requires repairs, cleaning or winterization, the Customer shall pay EXCEL
      Trailers Ltd. the cost of such repairs, cleaning or winterization at the prevailing EXCEL
      Trailers Ltd. rate plus materials, if required. Cleaning fee - $40/hour (if applicable)
    </p>
    <br />
    <p class="note-section__text">5. FORCE MAJEURE</p>
    <p class="note-section__text">
      In the event EXCEL's performance under this Agreement, including its ability to deliver the
      Equipment, is hindered or impaired due to 'Force Majeure' as described herein then any
      resulting delay or failure on the part of EXCEL shall not constitute a default hereunder or
      give rise to any claim for damages. Force Majeure shall include acts of God, strikes, lockouts
      or other significant industrial disturbances, sabotage, war, blockades, insurrections or
      riots, fires, walkouts, arrests and restrains of rules and people, civil disturbances,
      explosions, malfunctions, interruptions, breakdowns, breakages or accidents to machinery,
      equipment or systems, shortages or commercial disruptions in the supply of materials,
      equipment or services, any legislative, administrative or judicial action, transportation
      restrictions or disruptions, road conditions, weather conditions, any other act, omission or
      event whether of the kind herein enumerated or otherwise not within the reasonable control of
      EXCEL. In the event that performance of this Agreement in the reasonable opinion of EXCEL is
      so affected, then EXCEL will notify the Customer in writing providing a workaround plan and
      shall complete the performance of the Agreement with such adjustments relating to timing as
      are reasonably required by the existence of the Force Majeure.
    </p>
    <p class="note-section__text">6. LIMITATION OF LIABILITY</p>
    <p class="note-section__text">
      Notwithstanding any other terms of this Agreement, no recovery against EXCEL on a claim of any
      kind shall exceed the total rental payments actually received by EXCEL for the Equipment over
      the terms of this Agreement.
    </p>
    <p class="note-section__text">7. APPLICABLE LAWS</p>
    <p class="note-section__text">
      This Agreement shall be construed in accordance with the governed by the laws of the Province
      of the EXCEL office as indicated herein.
    </p>
    <p class="note-section__text">8. CONSEQUENTIAL DAMAGES</p>
    <p class="note-section__text">
      Neither party hereto shall be liable to the other in connection with this Agreement of the
      subject matter hereof for any indirect, incidental, special, or consequential damages,
      including but not limited to any overhead costs, loss of revenue, cost of capital, or loss of
      profit or business opportunity or reputation or form any cause whatsoever, whether such
      liability arises out of contract, tort (including negligence), strict liability or otherwise.
    </p>
    <p class="note-section__text">9. POSSESSIONS AND INSPECTIONS</p>
    <p class="note-section__text">
      The Customer shall keep the Equipment in its own custody and control and will not move the
      Equipment from the location stipulated herein without the previous consent in writing of EXCEL
      first had and obtained; and will permit EXCEL at all reasonable times to have access to the
      Equipment to inspect the condition thereof. Customer represents to EXCEL that the Equipment
      will be used for business purposes only.
    </p>
    <p class="note-section__text">10. TIME OF PAYMENT AND DEFAULT</p>
    <p class="note-section__text">
      Time is of the essence. The Customer shall during the term of this Lease pay to EXCEL in
      advance without abatement, set off or counterclaim, the monthly rental payment set forth, the
      first such payment to be on the commencement date and each subsequent payment on the first day
      of each succeeding month thereafter during the said term, and any extensions thereof. Provided
      always that in the event the said term should commence and/or terminate during a month, then
      the rent payable for the portion of the month shall be prorated on the basis of the thirty-day
      month. If the rent for the Equipment shall not be paid at the time and in the manner
      aforesaid, the Customer shall pay to EXCEL interest on the arrears at the rate of 5% per month
      until the time of payment or up to and until EXCEL shall retake or receive possessions
      pursuant to the terms of the Lease, whichever of such times shall first occur and all costs
      and expenses incurred by EXCEL obtaining payment of such arrears or the sums required to be
      paid by the Customer pursuant to the terms of the Customer, or in obtaining possessions and
      whether or not any action or suit shall have been instituted shall be recoverable from the
      Customer in addition , and without prejudice, to EXCEL's right to damages from a breach of the
      Lease. If the Customer shall make default in punctual payment of the monies required to be
      paid pursuant to the provisions of this Lease or any renewal or any extension thereof or if a
      Receiving Order in Bankruptcy shall be made against the Customer, or if the Customer shall
      call a meeting of the Customer's creditors, or if the Customer shall execute any assignment
      for the benefit of the Customer's creditors, or if the Customer (if a limited company) shall
      enter into compulsory or voluntary liquidation, or if the Customer shall fall to observe and
      perform the terms and conditions of this Lease on the Customer's part to be observed and
      performed, or if the Customer shall do or cause to be done or permit or suffer any act or
      thing whereby EXCEL's rights in the Equipment may be prejudiced or put in jeopardy, EXCEL may,
      without any notice terminate this Agreement, and it shall thereupon be lawful for EXCEL to
      retake possession of the Equipment and for that purpose to enter into or upon any premises
      where the same may be, and the determination of the Lease under this clause shall not affect
      the right of EXCEL to recover from the Customer any monies due to EXCEL under this Lease, or
      damages for breach thereof, provided always that Customer shall indemnify and save harmless
      EXCEL from any claims, causes of action, which may arise by reason of EXCEL retaking
      possession of the Equipment.
    </p>
    <p class="note-section__text">11. THIRD PARTY PAYMENTS</p>
    <p class="note-section__text">
      The Customer will duly obtain and punctually pay all rent, rates, taxes, including property
      taxes, regulatory approvals, permits, charges, licenses, tariffs, tolls and impositions of
      every nature and kind whatsoever incurred and which may be payable in respect to the
      transportation, possession or use of the Equipment or in the respect of the lands on which the
      Equipment shall from time to time be situated and shall produce all receipts for such payment
      to EXCEL on demand and will protect the Equipment against distress, execution or seizure and
      indemnify EXCEL against all losses, cost, charges, demands or expenses incurred by the
      Customer by reason of or in respect of any distress, execution, seizure or failure of the
      Customer to make payments as herein required. Nor shall EXCEL be in any way obliged to
      ascertain, obtain and/or advise the Customer on any requirements of any jurisdiction in which
      the Equipment is to be transported in, used by or in possession of the Customer.
    </p>
    <p class="note-section__text">12. EVENT OF DESTRUCTION OR PARTIAL DESTRUCTION</p>
    <p class="note-section__text">
      The Customer shall notify EXCEL immediately (within a maximum of twenty-four (24) hours) of
      the Customer first becoming aware of any event of damage that may in any way reduce the
      integrity of the Equipment of where the Equipment has been destroyed, lost or stolen including
      any event listed in Article 12 of this Lease. If the Equipment shall be damaged or destroyed
      by any cause, all monies received or receivable with respect to such insurance as aforesaid,
      shall forthwith be received by EXCEL. In the event that insurance monies payable are, for any
      reason whatsoever, insufficient to cover, in case of damage, repair cost, in case of
      destruction, the Insurance Value as set forth in the face of the Lease Quotation, the Customer
      shall upon demand pay to EXCEL an amount equal to such deficiency. In the event that the
      damage should render the Equipment unfit for use by the Customer, then this Lease shall at the
      election of EXCEL be terminated on Five (5) days written notice directed to the Customer and
      the Customer shall pay to EXCEL such rentals as may then be due. In the event that EXCEL does
      not elect to terminate this Lease by reason of the destruction or partial destruction of the
      Equipment then EXCEL shall replace the Equipment with the same or similar Equipment (but not
      of lesser quality) within thirty (30) days from the date of such destruction and there shall
      be no abatement of rent whatsoever.
    </p>
    <p class="note-section__text">13. INDEMNIFICATIONS</p>
    <p class="note-section__text">
      The Customer shall indemnify and save harmless EXCEL from and against all liability, damages,
      costs, claims, suits or actions arising out of: <br />
      (a)Any damage to the Equipment, excluding damage caused by EXCEL, its agents or
      sub-contractors. <br />
      (b)The use, occupancy or possession of the Equipment by the Customer including any
      environmental claims. <br />
      (c)Any injury to person or persons including death or damage to property occasioned by the use
      or occupancy of the said Equipment or during the transportation of the Equipment by the
      Customer, its employees or agents. <br />
      (d)EXCEL's ownership of the Equipment. <br />
      EXCEL makes no representation or warranty, express or implied that the Equipment is, or will
      be, free of mould, bacteria or other biological contaminants of any nature whatsoever. The
      Customer acknowledges that it is an express term of this Agreement that EXCEL and its
      officers, directors, employees, agents, affiliates and other representatives shall have no
      responsibility or liability, in contract, tort or otherwise, to the Customer or its officers,
      directors, employees, agents, affiliates and other representatives or any other person who
      occupies or otherwise uses the Equipment, for any loss, damages or other claim arising from
      any presence of mould, bacteria or other biological contaminants in or on the Equipment. In
      the event that EXCEL and/or any of its officers, directors, employees, agents, affiliates and
      other representatives are found liable in any amount, tort or otherwise, to any person for any
      loss, damages or other claims arising from any presence of mould, bacteria or other biological
      contaminants in or on the Equipment, the Customer agrees to fully indemnify EXCEL and its
      officers, directors, employees, agents, affiliates and other representatives in respect of all
      amounts for which they are found liable. Further, the Customer agrees to indemnify EXCEL and
      its officers, directors, employees, agents, affiliates and other representatives for all legal
      fees and disbursements incurred in responding to any claim against any of them relating to or
      rising from presence or alleged presence of mould, bacteria or other biological contaminants
      in or on the Equipment.
    </p>
    <p class="note-section__text">14. TITLE</p>
    <p class="note-section__text">
      EXCEL retains full title and rights to the Equipment and the Customer will not during the term
      of this Lease, sell, offer for sale, assign, mortgage, pledge, underlet, lend or otherwise
      deal with the Equipment or any part or parts thereof, or with the benefit of this Lease or do
      or cause or permit to be done any act or thing which might prejudice EXCEL's rights in the
      Equipment without EXCEL's consent in writing. The Customer agrees that the Equipment is a
      chattel (moveable) and if placed on the land owned by the Customer, shall not be deemed to be
      a fixture and if placed on lands other than those owned by the Customer, the Customer shall
      secure from the owner of such lands an undertaking that the Equipment shall not be deemed a
      fixture and may be removed at any time without notice.
    </p>
    <p class="note-section__text">15. CONDITION OF EQUIPMENT</p>
    <p class="note-section__text">
      The Customer acknowledges that the Equipment has been used by other parties since the date of
      manufacture.
    </p>
    <p class="note-section__text">16. LIABILITY FOR DAMAGE TO RUNNING</p>
    <p class="note-section__text">
      In the event that the Customer shall move the Equipment on under-carriages furnished by EXCEL
      the Customer shall be responsible for all tire and wheel damages and damage to under-carriage
      resulting from transport and will pay to EXCEL in addition to the rent herein provided, the
      costs of repair thereto.
    </p>
    <p class="note-section__text">17. INSTALLATION REQUIREMENTS</p>
    <p class="note-section__text">
      Unless otherwise agreed to in writing between the Customer and EXCEL, the Customer shall
      ensure that a level compact, truck accessible site is available for placement of the
      Equipment. If due to any act or omission of the Customer extra time is required at the
      delivery site beyond the customary allowed by EXCEL, such time shall be paid by Customer to
      EXCEL for truck and labour at EXCEL's prevailing rate then in effect. Should the Customer fail
      to provide a suitable and adequate site, including snow removal, Customer shall pay to EXCEL
      all resulting delivery and return costs including rental payments. Customer is responsible for
      all connections, maintenance, and disconnection of all utilities such as but not limited to
      electrical, sewer, fuel and water services to the Equipment unless otherwise expressly set out
      in this Agreement.
    </p>
    <p class="note-section__text">18. DAMAGE DURING TRANSPORTATION</p>
    <p class="note-section__text">
      Where EXCEL is responsible for transportation of the Equipment EXCEL shall not be responsible
      for any loss or damage to the Equipment during transportation unless details of such loss or
      damage are: <br />
      (a)Noted on the trucker's bill of lading presented at the time of delivery of the Equipment to
      the Customer; or <br />
      (b)Otherwise communicated to EXCEL in writing at the time of such delivery.
    </p>
    <p class="note-section__text">19. NOTICES</p>
    <p class="note-section__text">
      Any notice provided by EXCEL or Customer to the other shall be in writing and may be mailed by
      prepaid registered post to the other party at the address shown above or at other address as
      may be substituted from time to time by proper notice and such notice shall be deemed to be
      received by the addressee on the third business day next following that on which it was
      mailed.
    </p>
  </div>
</div>
