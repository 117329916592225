import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auth-form-wrapper',
  templateUrl: './auth-form-wrapper.component.html',
  styleUrls: ['./auth-form-wrapper.component.scss'],
})
export class AuthFormWrapperComponent {
  @Input() title!: string;
  @Input() description!: string;
}
