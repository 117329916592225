import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Image } from 'src/app/core/models/accessory.model';

@Component({
  selector: 'app-trailer-upload-image',
  templateUrl: './trailer-upload-image.component.html',
  styleUrls: ['./trailer-upload-image.component.scss'],
})
export class TrailerUploadImageComponent implements OnInit {
  files: File[] = [];
  previews: string[] = [];
  openDetails = false;
  removableImages: string[] = [];
  mainImagePreview!: string;

  @Input() mainImage!: Image | null;
  @Input() images!: Image[] | null;
  @Input() isEditMode!: boolean;
  @Input() isCreationMode!: boolean;
  @Output() updateFiles = new EventEmitter<File[]>();
  @Output() updateMainImage = new EventEmitter<File>();
  @Output() changeDetailsView = new EventEmitter<boolean>();
  @Output() deleteImages = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit(): void {}

  removeImageFromList(image: Image): void {
    this.removableImages.push(image.id);
    if (this.images) {
      this.images = this.images?.filter((item) => item.id !== image.id);
    }
    this.deleteImages.emit(this.removableImages);
  }

  openImageDetails(): void {
    if (!this.isCreationMode) {
      this.openDetails = true;
      this.changeDetailsView.emit(this.openDetails);
    }
  }

  closeImageDetails(): void {
    this.openDetails = false;
    this.changeDetailsView.emit(this.openDetails);
  }

  removeImage(index: number): void {
    this.files.splice(index, 1);
    this.previews.splice(index, 1);
    this.updateFiles.emit(this.files);
  }

  onFileDropped($event: FileList): void {
    if (!this.isEditMode) {
      this.prepareFilesList($event);
    }
  }

  fileBrowseHandler(event: Event): void {
    if (!this.isEditMode) {
      const files = (event.target as HTMLInputElement).files;
      if (files) {
        this.prepareFilesList(files);
      }
    }
  }

  mainFileBrowserHandler(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (this.mainImage?.contentPath) {
          this.mainImage.contentPath = e.target.result;
        } else {
          this.mainImage = {
            id: '0',
            downloadPath: '',
            contentType: '',
            contentPath: e.target.result,
          };
        }
      };

      reader.readAsDataURL(file);
      this.updateMainImage.emit(file);
    }
  }

  prepareFilesList(files: FileList): void {
    if (files && files[0]) {
      this.onHandleFilePreviews(files);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.files.push(file);
      }
      this.updateFiles.emit(this.files);
    }
  }

  private onHandleFilePreviews(files: FileList): void {
    const numberOfFiles = files.length;
    for (let i = 0; i < numberOfFiles; i++) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.previews.push(e.target.result);
      };

      reader.readAsDataURL(files[i]);
    }
  }
}
