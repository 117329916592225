<div class="send-email-dialog">
  <div class="send-email-dialog__header">
    <div class="send-email-dialog__title">Send Link To Email</div>
    <i class="icon-close send-email-dialog__icon" (click)="closePopup(false)"></i>
  </div>
  <form class="send-email-dialog__form form" [formGroup]="form">
    <div class="send-email-dialog__form-group">
      <div class="send-email-dialog__input-control">
        <input
          type="email"
          placeholder="Enter your email address"
          [class.form__input-control--active]="!!email?.value"
          class="form__input-control send-email-dialog__input"
          formControlName="email"
          [ngClass]="{ form__error: email?.invalid && (email?.dirty || email?.touched) }"
        />
        <app-field-message-wrapper [inputControl]="form.get('email')">
          <span class="required">Email is required</span>
          <span class="valid-email">Email must be a valid email address</span>
        </app-field-message-wrapper>
      </div>
      <button class="button button--main" [disabled]="form.invalid" (click)="submit()">SEND</button>
    </div>
  </form>
</div>
