import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortItemsHelperService {
  getSale(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'ID':
        sortItem = 'NUM_ID';
        sortOrder = 'DESCENDING';
        break;
      case 'Name':
        sortItem = 'NAME';
        sortOrder = 'DESCENDING';
        break;
      case 'Size':
        sortItem = 'SIZE';
        sortOrder = 'DESCENDING';
        break;
      case 'Price':
        sortItem = 'LOAD_UPLOAD';
        sortOrder = 'DESCENDING';
        break;
      case 'Weight':
        sortItem = 'WEIGHT';
        sortOrder = 'DESCENDING';
        break;
      case 'Type':
        sortItem = 'TYPE';
        sortOrder = 'DESCENDING';
        break;
      case 'Building Size':
        sortItem = 'BUILDING_SIZE';
        sortOrder = 'DESCENDING';
        break;
      case 'Hitch':
        sortItem = 'HITCH';
        sortOrder = 'DESCENDING';
        break;
      case 'Occupancy':
        sortItem = 'OCCUPANCY';
        sortOrder = 'DESCENDING';
        break;
      case 'Power':
        sortItem = 'POWER';
        sortOrder = 'DESCENDING';
        break;
      case 'Heat':
        sortItem = 'HEAT';
        sortOrder = 'DESCENDING';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }

  getCityDeliveries(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'City':
        sortItem = 'CITY';
        sortOrder = 'DESCENDING';
        break;
      case 'Hours':
        sortItem = 'HOURS';
        sortOrder = 'DESCENDING';
        break;
      case 'Transportation Mode':
        sortItem = 'TRANSPORTATION_MODE';
        sortOrder = 'DESCENDING';
        break;
      case 'Load':
        sortItem = 'LOAD_UPLOAD';
        sortOrder = 'DESCENDING';
        break;
      case 'Travel':
        sortItem = 'TRAVEL';
        sortOrder = 'DESCENDING';
        break;
      case 'Rate':
        sortItem = 'RATE';
        sortOrder = 'DESCENDING';
        break;
      case 'Estimated':
        sortItem = 'ESTIMATED';
        sortOrder = 'DESCENDING';
        break;
      case 'Total':
        sortItem = 'TOTAL';
        sortOrder = 'DESCENDING';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }

  getOrderHistory(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'Order ID':
        sortItem = 'NUM_ID';
        sortOrder = 'DESCENDING';
        break;
      case 'Date':
        sortItem = 'DATE';
        sortOrder = 'DESCENDING';
        break;
      case 'Client Name (Low to High)':
        sortItem = 'CLIENT_NAME';
        sortOrder = 'DESCENDING';
        break;
      case 'Client Name (High to Low)':
        sortItem = 'CLIENT_NAME';
        sortOrder = 'ASCENDING';
        break;
      case 'Duration':
        sortItem = 'DURATION';
        sortOrder = 'DESCENDING';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }

  getClientSort(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'ID Number':
        sortItem = 'ID_NUMBER';
        break;
      case 'Amount':
        sortItem = 'AMOUNT';
        break;
      case 'Phone Number':
        sortItem = 'PHONE_NUMBER';
        break;
      case 'Name (A to Z)':
        sortItem = 'NAME_A_TO_Z';
        break;
      case 'Name (Z to A)':
        sortItem = 'NAME_Z_TO_A';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }

  getTrailerStatisticsSort(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'Quantity':
        sortItem = 'QUANTITY';
        sortOrder = 'DESCENDING';
        break;
      case 'Size':
        sortItem = 'SIZE';
        sortOrder = 'DESCENDING';
        break;
      case 'Loading capacity (Low to High)':
        sortItem = 'LOADING_CAPACITY_LOW_TO_HIGH';
        sortOrder = 'ASCENDING';
        break;
      case 'Loading capacity (High to Low)':
        sortItem = 'LOADING_CAPACITY_HIGH_TO_LOW';
        sortOrder = 'DESCENDING';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }

  getTrailerInventorySort(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'ID Number':
        sortItem = 'NUM_ID';
        sortOrder = 'DESCENDING';
        break;
      case 'Size':
        sortItem = 'SIZE';
        sortOrder = 'DESCENDING';
        break;
      case 'Loading capacity (Low to High)':
        sortItem = 'LOADING_CAPACITY';
        sortOrder = 'ASCENDING';
        break;
      case 'Loading capacity (High to Low)':
        sortItem = 'LOADING_CAPACITY';
        sortOrder = 'DESCENDING';
        break;
      case 'Status':
        sortItem = 'STATUS';
        sortOrder = 'DESCENDING';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }

  getOfficeTrailersSort(item: string): {
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  } {
    let sortItem = '';
    let sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
    switch (item) {
      case 'ID Number (Low to High)':
        sortItem = 'NUM_ID';
        sortOrder = 'ASCENDING';
        break;
      case 'ID Number (High to Low)':
        sortItem = 'NUM_ID';
        sortOrder = 'DESCENDING';
        break;
      case 'Size (Low to High)':
        sortItem = 'SIZE';
        sortOrder = 'ASCENDING';
        break;
      case 'Size (High to Low)':
        sortItem = 'SIZE';
        sortOrder = 'DESCENDING';
        break;
      case 'Loading capacity (Low to High)':
        sortItem = 'LOADING_CAPACITY';
        sortOrder = 'ASCENDING';
        break;
      case 'Loading capacity (High to Low)':
        sortItem = 'LOADING_CAPACITY';
        sortOrder = 'DESCENDING';
        break;
      default:
        break;
    }

    return { sortItem, sortOrder };
  }
}
