import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private endpoint = environment.api;

  constructor(private http: HttpClient) {}

  getMe(): Observable<User> {
    const url: string = `${this.endpoint}admin/users/me`;

    return this.http.get<User>(url);
  }
}
