<div class="shared-table">
  <div class="shared-table__header">
    <div class="shared-table__title" *ngIf="title">{{ title }}</div>
    <ng-content select=".header"></ng-content>
    <div
      class="shared-table__actions"
      [ngStyle]="{ 'grid-template-columns': 'repeat(' + acceptableActions.length + ', 55px)' }"
    >
      <div
        class="shared-table__export"
        *ngIf="acceptableActions.includes('Export')"
        (click)="onExport()"
      >
        <i class="icon-download shared-table__icon"></i>
        <p class="shared-table__actions-text">Export</p>
      </div>
      <div
        class="shared-table__import"
        *ngIf="acceptableActions.includes('Import')"
        (click)="onImport()"
      >
        <i class="icon-upload shared-table__icon"></i>
        <p class="shared-table__actions-text">Import</p>
      </div>
      <div
        class="shared-table__sort"
        [popper]="popperSort"
        [popperPlacement]="'left'"
        [popperHideOnClickOutside]="true"
        [popperDisableAnimation]="true"
        [popperTrigger]="'click'"
        [popperApplyArrowClass]="'shared-table__arrow'"
        [popperPositionFixed]="true"
        (popperOnShown)="onShownSortPopover()"
        (popperOnHidden)="onHideSortPopover()"
        *ngIf="acceptableActions.includes('Sort')"
      >
        <i class="shared-table__icon icon-sort" [class.icon-sort--active]="activeSortPopover"></i>
        <p class="shared-table__actions-text">Sort</p>
      </div>
      <div
        class="shared-table__filter"
        [popper]="popperFilter"
        [popperPlacement]="'left'"
        [popperHideOnClickOutside]="true"
        [popperDisableAnimation]="true"
        [popperTrigger]="'click'"
        [popperApplyArrowClass]="'shared-table__arrow'"
        [popperPositionFixed]="true"
        (popperOnShown)="onShownFilterPopover()"
        (popperOnHidden)="onHideFilterPopover()"
        *ngIf="acceptableActions.includes('Filter')"
      >
        <i
          class="shared-table__icon icon-filter"
          [class.icon-filter--active]="activeFilterPopover"
        ></i>
        <p class="shared-table__actions-text">Filter</p>
      </div>
    </div>
  </div>
  <table [class]="'shared-table__table ' + class">
    <tr class="shared-table__table-header">
      <th
        *ngFor="let header of headers; let i = index"
        class="shared-table__header-item"
        [class.shared-table__header-item--last-item]="
          headers.length - 1 === i && (header === 'ADD_ACTION' || header === '')
        "
        [class.shared-table__header-item--pl]="i === 0"
      >
        <div
          class="shared-table__resize-handle"
          *ngIf="i !== headers.length - 1"
          (mousedown)="onResizeStart($event, header)"
        ></div>
        <span *ngIf="header !== 'ADD_ACTION'">{{ header }}</span>
        <button
          *ngIf="header === 'ADD_ACTION'"
          class="button button--main shared-table__action-button"
          (click)="addItem(cityFilter || priceListFilter)"
        >
          +
        </button>
      </th>
    </tr>
    <ng-content></ng-content>
    <div class="shared-table__pagination">
      <ng-content select=".pagination"></ng-content>
    </div>
  </table>
</div>

<popper-content #popperSort>
  <div class="popper form">
    <p class="popper__title">Sort By</p>
    <div class="popper__list">
      <div class="popper__item" *ngFor="let header of sortableItems">
        <label class="form__checkbox-block">
          <input
            type="checkbox"
            [name]="header"
            class="form__checkbox"
            [checked]="sortableItem === header"
            (change)="selectSortItem($event, header)"
          />
          {{ header }}
        </label>
      </div>
    </div>
    <div class="popper__actions">
      <button
        class="button button--cancel popper__button"
        (click)="clearAllSortItems(); popperSort.hide()"
      >
        Clear All
      </button>
      <button
        class="button button--main popper__button"
        (click)="viewSortResults(); popperSort.hide()"
      >
        View Results
      </button>
    </div>
  </div>
</popper-content>

<popper-content #popperFilter>
  <div class="popper form">
    <p class="popper__title">Filter by</p>
    <div class="popper__list">
      <div class="popper__item" *ngFor="let header of headers">
        {{ header }}
      </div>
    </div>
    <div class="popper__actions">
      <button class="button button--cancel popper__button" (click)="clearAllSortItems()">
        Clear All
      </button>
      <button
        class="button button--main popper__button"
        (click)="viewSortResults(); popperFilter.hide()"
      >
        View Results
      </button>
    </div>
  </div>
</popper-content>
