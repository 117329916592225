import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen } from 'rxjs/operators';
import { AuthApiService } from '../services/api/auth-api.service';
import { NotificationService } from '../services/toastr-notification.service';

export const maxRetries = 2;
export const delayMs = 2000;

@Injectable({
  providedIn: 'root',
})
export class ErrorIntercept implements HttpInterceptor {
  constructor(private toastr: NotificationService, private authService: AuthApiService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((error) => {
        return error.pipe(
          mergeMap((error, index) => {
            if (index < maxRetries && error.status == 500) {
              return of(error).pipe(delay(delayMs));
            }

            throw error;
          })
        );
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastr.error(error?.error?.message || 'Something went wrong!');
        if (
          error?.error?.message?.includes('Access is denied') ||
          error?.error?.message?.includes('Доступ заборонений')
        ) {
          this.authService.logoutMode();
        }
        return throwError(() => error);
      })
    );
  }
}
