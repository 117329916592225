import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionComponent {
  public showTermsAndCondition = true;

  public toggleTermsAndCondition(): void {
    this.showTermsAndCondition = !this.showTermsAndCondition;
  }
}
