<div class="office-trailers-calendar">
  <div class="office-trailers-calendar__header">
    <div class="office-trailers-calendar__month">
      <i class="icon-left-arrow" (click)="choosePreviousMonth()"></i>
      {{ currentMonth | date: 'MMMM, y' }}
      <i class="icon-right-arrow" (click)="chooseNextMonth()"></i>
    </div>
    <div>
      <div
        class="office-trailers-calendar__filters"
        [popper]="popperSort"
        [popperPlacement]="'bottom'"
        [popperHideOnClickOutside]="true"
        [popperDisableAnimation]="true"
        [popperTrigger]="'click'"
        [popperApplyArrowClass]="'shared-table__arrow'"
        [popperPositionFixed]="true"
        (popperOnShown)="onShownSortPopover()"
        (popperOnHidden)="onHideSortPopover()"
      >
        <i class="shared-table__icon icon-sort" [class.icon-sort--active]="activeSortPopover"></i>
        <p class="shared-table__actions-text">Filter</p>
      </div>
    </div>
  </div>
  <div class="office-trailers-calendar__content">
    <div class="office-trailers-calendar__content-item">
      <div>
        <table class="office-trailers-calendar__table">
          <tr class="office-trailers-calendar__table-header">
            <th
              *ngFor="let header of headers; let i = index"
              class="office-trailers-calendar__header-item"
              [class.office-trailers-calendar__header-item--pl]="i === 0"
            >
              {{ header }}
            </th>
          </tr>
          <tr
            *ngFor="let trailer of trailerCalendar; let i = index"
            class="office-trailers-calendar__table-column"
            [ngClass]="
              daterange.start && daterange.end
                ? trailer.status === 'AVAILABLE'
                  ? 'office-trailers-calendar__table-column--available'
                  : 'office-trailers-calendar__table-column--not-available'
                : ''
            "
            [class.office-trailers-calendar__active-trailer]="isContract && (activeTrailer?.id === trailer?.id)"
            [class.office-trailers-calendar__not-active-trailer]="isContract && (activeTrailer && activeTrailer?.id !== trailer?.id)"
            (click)="isContract && selectTrailer(trailer)"
          >
            <td
              class="office-trailers-calendar__table-row office-trailers-calendar__table-row--first-child"
            >
              2022-07
            </td>
            <td class="office-trailers-calendar__table-row">
              {{ trailer?.unitNumber }}
            </td>
            <td class="office-trailers-calendar__table-row">
              {{ trailer?.status }}
            </td>
            <td class="office-trailers-calendar__table-row">
              {{ trailer?.dimensionsData?.sizeName }}
            </td>
            <td class="office-trailers-calendar__table-row">
              {{ trailer?.dimensionsData?.typeName | titlecase }}
            </td>
            <td class="office-trailers-calendar__table-row">
              <div
                class="shared-table__sort"
                [popper]="popperLink"
                [popperDisabled]="!!(daterange.start && daterange.end)"
                (click)="openDialogToSeeReservationDetails(trailer)"
                [popperPlacement]="'bottom'"
                [popperHideOnClickOutside]="true"
                [popperTrigger]="'click'"
                [popperApplyArrowClass]="'shared-table__arrow'"
                [popperPositionFixed]="true"
                *ngIf="!isContract"
              >
                <i class="shared-table__icon icon-link"></i>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <div class="office-trailers-calendar__calendar">
          <div class="office-trailers-calendar__calendar-days">
            <div
              class="office-trailers-calendar__calendar-day"
              *ngFor="let day of days"
              [ngClass]="
                daterange.start && daterange.end
                  ? isAvailableDayForRent(day)
                    ? 'office-trailers-calendar__calendar-day--available'
                    : 'office-trailers-calendar__calendar-day--not-available'
                  : ''
              "
            >
              {{ day }}
            </div>
          </div>
          <div
            class="office-trailers-calendar__available-days"
            *ngFor="let trailer of trailerCalendar; let i = index"
            [class.office-trailers-calendar__active-trailer]="isContract && (activeTrailer?.id === trailer?.id)"
            [class.office-trailers-calendar__not-active-trailer]="isContract && (activeTrailer && activeTrailer?.id !== trailer?.id)"
            (click)="isContract && selectTrailer(trailer)"
          >
            <div
              class="office-trailers-calendar__available-day"
              [class.office-trailers-calendar__available-day--last-child]="
                i === trailerCalendar.length - 1
              "
              [class.office-trailers-calendar__available-day--rented]="
                isRentedDay(trailer.bookedDates, day)
              "
              [class.office-trailers-calendar__available-day--on-hold]="
                trailer.status === 'ON_HOLD'
              "
              [ngClass]="
                daterange.start &&
                daterange.end &&
                (!isAvailableDayForRent(day) ||
                  isRentedDay(trailer.bookedDates, day) ||
                  trailer.status !== 'AVAILABLE')
                  ? 'office-trailers-calendar__available-day--not-available'
                  : 'office-trailers-calendar__available-day--available'
              "
              *ngFor="let day of days"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<popper-content #popperLink>
  <div class="popper form">
    <p class="popper__title">
      In order to open reservation details please firstly choose date range! Click on
      <span class="popper__title--link" (click)="date.click(); popperLink.hide()">calendar</span>
    </p>
  </div>
</popper-content>

<popper-content #popperSort>
  <div class="popper form popper-size" [formGroup]="form">
    <p class="popper__title" >Filters</p>
    <div class="popper__list">
      <div class="popper__item">
        <div class="office-trailers-calendar__datepicker" (click)="date.click()">
          <div class="form__label">Choose Date</div>
          <i class="icon-calendar office-trailers-calendar__calendar-icon"></i>
          <input
            type="text"
            name="daterangeInput"
            class="office-trailers-calendar__daterangepicker"
            daterangepicker
            #date
            [options]="options"
            (selected)="selectedDate($event, daterange)"
          />
        </div>
      </div>
      <div class="popper__item">
        <div class="form__label">Choose Size</div>
        <select
          class="office-trailers-calendar__select"
          (change)="changeSize($event)"
          formControlName="size"
        >
          <option disabled value="" selected>Choose Size</option>
          <option *ngFor="let size of trailersSizes"
                  [value]="size.size">{{ size.friendlyName }}</option>
        </select>
      </div>
      <div class="popper__item">
        <div class="form__label">Choose Type</div>
        <select
          class="office-trailers-calendar__select"
          (change)="changeType($event)"
          formControlName="type"
        >
          <option disabled value="" selected>Choose Type</option>
          <option *ngFor="let type of trailersTypes"
                  [value]="type.friendlyName">{{ type.friendlyName }}</option>
        </select>
      </div>
      <div class="popper__item">
        <button
          class="button button--main office-trailers-calendar--clear-filters"
          (click)="onClearDateFilters()"
        >
          CLEAR FILTERS
        </button>
      </div>
    </div>
  </div>
</popper-content>
