import { User } from './../../core/models/user.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthApiService } from 'src/app/core/services/api/auth-api.service';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
import { RouteInfo, ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { UserApiService } from 'src/app/core/services/api/user-api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private listTitles: RouteInfo[] = [];
  location!: Location;
  user!: User | null;

  private readonly destroy$: Subject<null> = new Subject();

  constructor(
    location: Location,
    private auth: AuthApiService,
    private router: Router,
    private authStorageService: AuthStorageService,
    private userService: UserApiService
  ) {
    this.location = location;
  }

  ngOnInit(): void {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.prepareToShowUser();
  }

  logout(): void {
    this.auth
      .logout()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.authStorageService.clearAuthStorage();
        this.router.navigate(['/login']);
      });
  }

  getTitle() {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      if (titlee?.includes(this.listTitles[item].path)) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private prepareToShowUser(): void {
    this.userService
      .getMe()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
      });
  }
}
