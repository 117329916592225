<div class="note-section">
  <p class="note-section__text note-section__text--bold">Please note:</p>
  <p class="note-section__text">
    Trailer Insurance is to be provided by the lessee <br />
    Heating/plumbing/electrical/data hook ups provided by others <br />
    Equipment is quoted subject to availability <br />
    Delivery price includes 1 hour to load and 1 hour to unload, any additional time at $165.00 per
    hour <br />
    Quote is based on exact dimensions and weights as provided. <br />
    Quote is based on exact pick up/delivery location <br />
    Quote is valid from 30 days from quote date. <br />
    Chain up if required will be an additional $100.00 <br />
    Pricing includes loading/unloading time as specified - any additional time will be charged out
    at the quoted hourly rate. <br />
    Provincial permits are included in all quotes up to 50,000lbs - City or County permits if
    required will be additional at cost + 15% <br />
    If load is unable to be delivered trailer Demurrage and or storage fees may be applicable.
    <br />
    All applicable taxes are extra
  </p>
</div>
