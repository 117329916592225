<div class="client-dialog">
  <div class="client-dialog__header">
    <div class="client-dialog__title">{{ title }}</div>
    <div class="client-dialog__action-icons">
      <i class="icon-close client-dialog__icon" (click)="closePopup(false)"></i>
    </div>
  </div>
  <form class="client-dialog__form form" [formGroup]="form">
    <div class="client-dialog__form-groups">
      <div class="form-group client-dialog__form-group">
        <div class="form__label">Name <span class="client-dialog__required">*</span></div>
        <input
          type="text"
          formControlName="name"
          placeholder="Please enter name"
          [class.form__input-control--active]="!!name?.value"
          class="form__input-control client-dialog__input-control"
        />
        <app-field-message-wrapper [inputControl]="name">
          <span class="required">Name is required</span>
        </app-field-message-wrapper>
      </div>
      <div class="form-group client-dialog__form-group">
        <div class="form__label">Company name <span class="client-dialog__required">*</span></div>
        <input
          type="text"
          formControlName="companyName"
          placeholder="Please enter company name"
          [class.form__input-control--active]="!!companyName?.value"
          class="form__input-control client-dialog__input-control"
        />
        <app-field-message-wrapper [inputControl]="companyName">
          <span class="required">Company name is required</span>
        </app-field-message-wrapper>
      </div>
      <div class="form-group client-dialog__form-group">
        <div class="form__label">
          Phone number
        </div>
        <input
          type="number"
          formControlName="phoneNumber"
          placeholder="Please enter phone number"
          [class.form__input-control--active]="!!phoneNumber?.value"
          class="form__input-control client-dialog__input-control"
        />
      </div>
      <div class="form-group client-dialog__form-group">
        <div class="form__label">
          Email <span class="client-dialog__required">*</span>
        </div>
        <input
          type="email"
          formControlName="email"
          placeholder="Enter email"
          [class.form__input-control--active]="!!email?.value"
          class="form__input-control client-dialog__input-control"
        />
        <app-field-message-wrapper [inputControl]="email">
          <span class="required">Email is required</span>
          <span class="valid-email">Email must be a valid email address</span>
        </app-field-message-wrapper>
      </div>
      <div class="form-group client-dialog__form-group">
        <div class="form__label">
          AP email <span class="client-dialog__required">*</span>
        </div>
        <input
          type="email"
          formControlName="apEmail"
          placeholder="Enter AP email address"
          [class.form__input-control--active]="!!apEmail?.value"
          class="form__input-control client-dialog__input-control"
        />
        <app-field-message-wrapper [inputControl]="apEmail">
          <span class="required">AP Email is required</span>
          <span class="valid-email">AP Email must be a valid email address</span>
        </app-field-message-wrapper>
      </div>
      <div class="form-group client-dialog__form-group">
        <div class="form__label">Office Address</div>
        <input
          type="text"
          formControlName="address"
          placeholder="Please enter address"
          [class.form__input-control--active]="!!address?.value"
          class="form__input-control client-dialog__input-control"
        />
      </div>
    </div>
    <div class="client-dialog__button">
      <button
        class="button button--main"
        (click)="save()"
        [disabled]="form.invalid"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
