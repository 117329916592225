import { TrailerFull } from 'src/app/core/models/trailer-full.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrailerStateManagementService {
  private trailerCreationSubject: Subject<TrailerFull> = new Subject<TrailerFull>();

  constructor() {}

  public getTrailerCreationEvent(): Subject<TrailerFull> {
    return this.trailerCreationSubject;
  }

  public sendTrailerCreationEvent(trailer: TrailerFull): void {
    this.trailerCreationSubject.next(trailer);
  }
}
