export const getFormattedDate = (date: Date | undefined): string => {
  const currentDate = date ? new Date(date) : new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString().slice(-2);

  return `${month}/${day}/${year}`;
};

export const getFormattedDateForServer = (date: Date | undefined): string => {
  const currentDate = date ? new Date(date) : new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();

  return `${year}-${month}-${day}`;
};

export const getFormattedDateTimeForServer = (date: Date | undefined): string => {
  const currentDate = date ? new Date(date) : new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
