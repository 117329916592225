import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsedTrailer, UsedTrailerPOST } from '../../models/used-trailer.model';

@Injectable({
  providedIn: 'root',
})
export class UsedTrailersApiService {
  private endpoint = environment.api;

  constructor(private http: HttpClient) {}

  getUsedTrailers(
    sortBy: string | undefined,
    sortOrder: 'ASCENDING' | 'DESCENDING' | undefined
  ): Observable<UsedTrailer[]> {
    const url: string = `${this.endpoint}admin/trailers/used`;

    let params = new HttpParams();

    if (sortBy) {
      params = params.append('sortBy', sortBy);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    return this.http.get<UsedTrailer[]>(url, { params });
  }

  createUsedTrailer(trailer: UsedTrailerPOST): Observable<UsedTrailer> {
    const url: string = `${this.endpoint}admin/trailers/used`;

    return this.http.post<UsedTrailer>(url, trailer);
  }

  addImagesToUsedTrailer(trailerId: string, formData: FormData): Observable<UsedTrailer> {
    const url: string = `${this.endpoint}admin/trailers/used/${trailerId}/images`;

    return this.http.patch<UsedTrailer>(url, formData);
  }

  editMainUsedTrailerImage(trailerId: string, formData: FormData): Observable<UsedTrailer> {
    const url: string = `${this.endpoint}admin/trailers/used/${trailerId}/images/main`;

    return this.http.patch<UsedTrailer>(url, formData);
  }

  deleteUsedTrailerImage(trailerId: string, imageId: string): Observable<UsedTrailer> {
    const url: string = `${this.endpoint}admin/trailers/used/${trailerId}/images/${imageId}`;

    return this.http.patch<UsedTrailer>(url, null);
  }

  editUsedTrailer(trailerId: string, trailer: UsedTrailerPOST): Observable<UsedTrailer> {
    const url: string = `${this.endpoint}admin/trailers/used/${trailerId}`;

    return this.http.put<UsedTrailer>(url, trailer);
  }

  deleteUsedTrailer(trailerId: string): Observable<null> {
    const url: string = `${this.endpoint}admin/trailers/used/${trailerId}`;

    return this.http.delete<null>(url);
  }
}
