import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientOffice } from '../../models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsStateManagementService {
  private clientCreationSubject: Subject<ClientOffice> = new Subject<ClientOffice>();

  constructor() {
  }

  public getClientCreationEvent(): Subject<ClientOffice> {
    return this.clientCreationSubject;
  }

  public sendClientCreationEvent(client: ClientOffice): void {
    this.clientCreationSubject.next(client);
  }

}
