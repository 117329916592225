import { Component, OnInit } from '@angular/core';
import { NotificationModel, NotificationType } from 'src/app/core/models/toastr-notification.model';
import { NotificationService } from 'src/app/core/services/toastr-notification.service';

@Component({
  selector: 'app-toastr-notification',
  templateUrl: './toastr-notification.component.html',
  styleUrls: ['./toastr-notification.component.scss'],
})
export class ToastrNotificationComponent implements OnInit {
  notifications: NotificationModel[] = [];

  constructor(public notificationService: NotificationService) {}

  ngOnInit(): void {
    this.prepareToShowAlerts();
  }

  removeNotification(notification: NotificationModel): void {
    this.notifications = this.notifications.filter((x) => x !== notification);
  }

  cssClass(notification: NotificationModel): string | undefined {
    if (!notification) {
      return;
    }
    switch (notification?.type) {
      case NotificationType.Success:
        return 'toast-success';
      case NotificationType.Error:
        return 'toast-error';
      case NotificationType.Info:
        return 'toast-info';
      case NotificationType.Warning:
        return 'toast-warning';
    }
  }

  private prepareToShowAlerts(): void {
    this.notificationService.getAlert().subscribe((alert: NotificationModel | null) => {
      this.notifications = [];
      if (!alert) {
        this.notifications = [];
        return;
      }
      this.notifications.push(alert);
      setTimeout(() => {
        this.notifications = this.notifications.filter((x) => x !== alert);
      }, 4000);
    });
  }
}
