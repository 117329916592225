import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Delivery, Order } from '../../models/order.model';

@Injectable({
  providedIn: 'root',
})
export class OrdersApiService {
  private endpoint = environment.api;

  constructor(private http: HttpClient) {}

  getOrderContact(orderId: string): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}`;

    return this.http.get<Order>(url);
  }

  downloadContact(orderId: string): Observable<ArrayBuffer> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/contract`;

    return this.http.post<ArrayBuffer>(url, null, {
      responseType: 'blob' as 'json',
    });
  }

  chooseOrderTrailer(
    startRentDate: string,
    endRentDate: string,
    dimensions: string,
    timeZone: number,
    orderId?: string
  ): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/trailers`;

    let params = new HttpParams();

    if (orderId) {
      params = params.set('orderId', orderId);
    }

    return this.http.post<Order>(
      url,
      { startRentDate, endRentDate, dimensions, timeZone },
      { params }
    );
  }

  sendReservationToEmail(orderId: string, email: string): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}?email=${email}`;

    return this.http.post<Order>(url, null);
  }

  orderDelivery(orderId: string, delivery: Delivery): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/delivery`;

    return this.http.post<Order>(url, delivery);
  }

  orderAccessories(orderId: string, accessories: string[]): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/accessories`;

    return this.http.post<Order>(url, { accessories });
  }

  orderContacts(orderId: string, formData: FormData): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/contacts`;

    return this.http.post<Order>(url, formData);
  }

  confirmOrder(orderId: string): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/confirm`;

    return this.http.post<Order>(url, null);
  }

  sendEmailForPayingRent(
    orderId: string,
    financialService: 'STRIPE' | 'E_TRANSFER',
    email: string
  ): Observable<null> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/rent/email`;

    return this.http.post<null>(url, { financialService, email });
  }

  sendPromoCode(orderId: string, promoCode: string): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/promo-code`;

    return this.http.post<Order>(url, { promoCode });
  }

  dismissPromoCode(orderId: string): Observable<Order> {
    const url: string = `${this.endpoint}admin/orders/${orderId}/promo-code/dismiss`;

    return this.http.post<Order>(url, {});
  }
}
