import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TrailerFull, TrailerFullData, TrailerPOST } from '../../models/trailer-full.model';
import { TrailerType } from '../../models/trailer-type.model';
import { TrailerData, TrailerFilters, TrailerSize, TrailerTypes } from '../../models/trailer.model';

export interface SearchPeriod {
  startDate: string;
  endDate: string;
}

export interface AvailablePeriod {
  startDate: string | null;
  endDate: string | null;
}

export interface TrailerCalendar {
  searchPeriod: SearchPeriod;
  availablePeriod: AvailablePeriod;
  sizes?: TrailerSize[];
  types?: TrailerTypes[];
}

@Injectable({
  providedIn: 'root',
})
export class TrailersApiService {
  private endpoint = environment.api;

  constructor(private http: HttpClient) {}

  getTrailers(
    limit: number,
    offset: number,
    sortBy: string | undefined,
    sortOrder: 'ASCENDING' | 'DESCENDING' | undefined
  ): Observable<TrailerData> {
    const url: string = `${this.endpoint}admin/trailers/statistics?limit=${limit}&offset=${offset}`;

    let params = new HttpParams();

    if (sortBy) {
      params = params.append('sortBy', sortBy);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    return this.http.post<TrailerData>(url, null, { params });
  }

  getTrailerTypes(): Observable<TrailerType[]> {
    const url: string = `${this.endpoint}admin/trailers/types`;

    return this.http.get<TrailerType[]>(url);
  }

  getTrailersByStatus(
    limit: number,
    offset: number,
    statuses: string[],
    sortBy: string | undefined,
    sortOrder: 'ASCENDING' | 'DESCENDING' | undefined
  ): Observable<TrailerFullData> {
    const url: string = `${this.endpoint}admin/trailers/search?limit=${limit}&offset=${offset}`;

    let params = new HttpParams();

    if (sortBy) {
      params = params.append('sortBy', sortBy);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    return this.http.post<TrailerFullData>(url, { statuses }, { params });
  }

  getTrailersCalendar(filterData: TrailerCalendar): Observable<any[]> {
    const url: string = `${this.endpoint}admin/trailers/calendar`;

    return this.http.post<any[]>(url, filterData);
  }

  getOfficeTrailersCalendar(filterData: TrailerCalendar): Observable<any[]> {
    const url: string = `${this.endpoint}admin/trailers/office/calendar`;

    return this.http.post<any[]>(url, filterData);
  }

  createTrailer(trailer: TrailerPOST): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers`;

    return this.http.post<TrailerFull>(url, trailer);
  }

  addImagesToTrailer(trailerId: string, formData: FormData): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/images`;

    return this.http.post<TrailerFull>(url, formData);
  }

  editMainTrailerImage(trailerId: string, formData: FormData): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/images/main`;

    return this.http.put<TrailerFull>(url, formData);
  }

  deleteTrailerImage(trailerId: string, imageId: string): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/images/${imageId}`;

    return this.http.delete<TrailerFull>(url);
  }

  editTrailer(trailerId: string, trailer: TrailerPOST): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}`;

    return this.http.put<TrailerFull>(url, trailer);
  }

  pinTrailer(trailerId: string, pin: boolean): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/pin?pin=${pin}`;

    return this.http.put<TrailerFull>(url, null);
  }

  setStatusOnHold(trailerId: string): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/status/hold`;

    return this.http.patch<TrailerFull>(url, null);
  }

  setStatusSold(trailerId: string): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/status/sold`;

    return this.http.patch<TrailerFull>(url, null);
  }

  setStatusAvailable(trailerId: string): Observable<TrailerFull> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}/status/available`;

    return this.http.patch<TrailerFull>(url, null);
  }

  deleteTrailer(trailerId: string): Observable<null> {
    const url: string = `${this.endpoint}admin/trailers/${trailerId}`;

    return this.http.delete<null>(url);
  }

  getTrailerSizes(): Observable<TrailerSize[]> {
    const url: string = `${this.endpoint}admin/trailers/sizes`;

    return this.http.get<TrailerSize[]>(url);
  }

  getTrailerFilters(): Observable<TrailerFilters> {
    const url: string = `${this.endpoint}admin/trailers/office/calendar/filters`;

    return this.http.get<TrailerFilters>(url);
  }
}
