import { Image } from './accessory.model';

export interface BookedDate {
  startDate: string;
  endDate: string;
}

export interface InventoryCalendar {
  id: string;
  name: string;
  description: string;
  power: string;
  hitch: string;
  manufacturer: string;
  year: number;
  bookedDates: BookedDate[];
  unitNumber: string;
  dimensions: string;
  trailerType: string;
  trailerTypeName: string;
  size: string;
  sizeName: string;
  status: string;
  loadingCapacity: number;
  image: Image;
}
