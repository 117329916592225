import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { Subject, takeUntil } from 'rxjs';
import { InventoryCalendar, BookedDate } from 'src/app/core/models/inventory-calendar.model';
import {
  TrailersApiService,
  TrailerCalendar,
} from 'src/app/core/services/api/trailers-api.service';
import { getFormattedDateForServer } from 'src/app/core/utils/formatted-date';
import { ReservationDetailsDialogComponent } from '../../inventory/inventory-calendar/reservation-details-dialog/reservation-details-dialog.component';
import { TrailerSize, TrailerTypes } from '../../../../../core/models/trailer.model';
import { OfficeTrailerCalendar } from '../../../../../core/models/office-trailer-calendar.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  currentMonth = new Date();
  daterange: { start: Date | null; end: Date | null; label: string } = {
    start: null,
    end: null,
    label: '',
  };
  days: number[] = [];
  rentedDays: Date[] = [];
  trailersSizes: TrailerSize[] = [];
  trailersTypes: TrailerTypes[] = [];
  activeSortPopover = false;
  selectedSize!: TrailerSize | any;
  selectedType!: TrailerTypes | any;
  activeTrailer: any;

  headers = ['ID', 'Unit', 'Status', 'Trailer Size', 'Type', ''];

  options = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  trailerCalendar: OfficeTrailerCalendar[] = [];

  form!: FormGroup;

  @Input() isContract = false;
  @Output() selectedTrailer = new EventEmitter<any>();

  private readonly destroy$ = new Subject();

  constructor(private trailersService: TrailersApiService,
              private fb: FormBuilder,) {}

  ngOnInit(): void {
    this.initForm();
    this.prepareToShowTrailerSizes();
  }

  onClearDateFilters(): void {
    this.daterange.start = null;
    this.daterange.end = null;
    this.selectedType = null;
    this.selectedSize = null;
    this.form.reset({
      size: '',
      type: ''
    });
    this.prepareToShowCalendar();
  }

  async openDialogToSeeReservationDetails(trailer: OfficeTrailerCalendar): Promise<void> {
    if (this.daterange.start && this.daterange.end) {
      const dialogPopup = new DialogInitializer(ReservationDetailsDialogComponent);

      dialogPopup.setCustomData({
        trailer,
        startDate: this.daterange.start,
        endDate: this.daterange.end,
      });

      dialogPopup.setConfig({
        width: '816px',
        layoutType: DialogLayoutDisplay.NONE,
        buttonPosition: 'right',
      });

      dialogPopup.openDialog$().subscribe(() => {
        this.daterange.start = null;
        this.daterange.end = null;
        this.prepareToShowCalendar();
      });
    }
  }

  isAvailableDayForRent(day: number): boolean {
    if (this.daterange.start && this.daterange.end) {
      const activeDate = new Date(
        this.currentMonth.getFullYear(),
        this.currentMonth.getMonth(),
        day
      );
      const startDate = new Date(this.daterange.start);
      const endDate = new Date(this.daterange.end);
      if (
        activeDate.getTime() <= endDate.getTime() &&
        activeDate.getTime() >= startDate.getTime()
      ) {
        return true;
      }
    }
    return false;
  }

  isRentedDay(orders: BookedDate[], day: number): boolean {
    const activeDate = new Date(
      this.currentMonth.getFullYear(),
      this.currentMonth.getMonth(),
      day,
      0
    );
    return (
      orders?.some(({ startDate, endDate }) => {
        const startRentedDate = new Date(
          new Date(startDate).getFullYear(),
          new Date(startDate).getMonth(),
          new Date(startDate).getDate(),
          0
        );
        const endRentedDate = new Date(
          new Date(endDate).getFullYear(),
          new Date(endDate).getMonth(),
          new Date(endDate).getDate(),
          0
        );
        return (
          activeDate.getTime() >= startRentedDate.getTime() &&
          activeDate.getTime() <= endRentedDate.getTime()
        );
      }) ?? false
    );
  }

  selectedDate(
    value: { start: Date; end: Date; label: string },
    datepicker?: { start: Date | null; end: Date | null; label: string }
  ) {
    if (datepicker) {
      datepicker.start = value.start;
      datepicker.end = value.end;
    }

    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.prepareToShowCalendar();
  }

  choosePreviousMonth(): void {
    const now = new Date(this.currentMonth);
    now.setDate(1);
    now.setMonth(now.getMonth() - 1);
    this.currentMonth = new Date(now);
    this.prepareToShowDaysInThisMonth();
  }

  chooseNextMonth(): void {
    const now = new Date(this.currentMonth);
    if (now.getMonth() == 11) {
      this.currentMonth = new Date(now.getFullYear() + 1, 0, 1);
    } else {
      this.currentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    }
    this.prepareToShowDaysInThisMonth();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private prepareToShowDaysInThisMonth() {
    const now = new Date(this.currentMonth);
    const amountOfDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    this.days = Array.from({ length: amountOfDays }, (_, i) => i + 1);
    this.prepareToShowCalendar(false);
  }

  private prepareToShowCalendar(prepareDays = true): void {
    let filterData: TrailerCalendar = {
      searchPeriod: {
        startDate: getFormattedDateForServer(
          new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth(), 1)
        ),
        endDate: getFormattedDateForServer(
          new Date(new Date(this.currentMonth.getFullYear(), this.currentMonth.getMonth() + 1, 0))
        ),
      },
      availablePeriod: {
        startDate: this.daterange.start ? getFormattedDateForServer(this.daterange.start) : null,
        endDate: this.daterange.end ? getFormattedDateForServer(this.daterange.end) : null,
      },
    };
    if (this.selectedSize) {
      filterData.sizes = [this.selectedSize]
    }
    if (this.selectedType) {
      filterData.types = [this.selectedType]
    }

    this.trailersService
      .getOfficeTrailersCalendar(filterData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((calendarData) => {
        this.trailerCalendar = calendarData;
        if (prepareDays) {
          this.prepareToShowDaysInThisMonth();
        }
      });
  }

  private prepareToShowTrailerSizes() {
    this.trailersService
      .getTrailerFilters()
      .subscribe((filters) => {
        this.trailersSizes = filters.officeTrailerSizes;
        this.trailersTypes = filters.officeTrailerTypes;
        this.prepareToShowCalendar();
      });
  }

  onShownSortPopover(): void {
    this.activeSortPopover = true;
  }

  onHideSortPopover(): void {
    this.activeSortPopover = false;
  }

  changeSize(event: any) {
    const size = this.trailersSizes.find((size) => size.size === event.target.value);
    if (size) {
      this.selectedSize = size;
    }
    this.prepareToShowCalendar();
  }

  changeType(event: any) {
    const type = this.trailersTypes.find((type) => type.friendlyName === event.target.value);

    if (type) {
      this.selectedType = type;
    }
    this.prepareToShowCalendar();
  }

  selectTrailer(trailer: any) {
    if (this.activeTrailer && (trailer.id === this.activeTrailer.id)) {
      this.activeTrailer = null;
    } else {
      this.activeTrailer = trailer;
    }

    this.selectedTrailer.emit(this.activeTrailer);
  }

  private initForm() {
    this.form = this.fb.group({
      size: [''],
      type: ['']
    })
  }
}
