import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OfficeTrailer } from '../../models/office-trailer.model';

@Injectable({
  providedIn: 'root',
})
export class OfficeTrailerStateManagementService {
  private officeTrailerCreationSubject: Subject<OfficeTrailer> = new Subject<OfficeTrailer>();

  constructor() {}

  public getOfficeTrailerCreationEvent(): Subject<OfficeTrailer> {
    return this.officeTrailerCreationSubject;
  }

  public sendOfficeTrailerCreationEvent(officeTrailer: OfficeTrailer): void {
    this.officeTrailerCreationSubject.next(officeTrailer);
  }
}
