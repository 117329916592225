<div
  *ngIf="inputControl?.invalid && (inputControl?.dirty || inputControl?.touched)"
  class="error-messages"
>
  <span class="error-messages__text-danger" *ngIf="inputControl?.errors?.['required']">
    <ng-content select=".required"></ng-content>
  </span>
  <span class="error-messages__text-danger" *ngIf="inputControl?.errors?.['validatePhoneNumber']">
    <ng-content select=".valid-phone"></ng-content>
  </span>
  <span class="error-messages__text-danger" *ngIf="inputControl?.errors?.['email']">
    <ng-content select=".valid-email"></ng-content>
  </span>
  <span class="error-messages__text-danger" *ngIf="inputControl?.errors?.['minlength']">
    <ng-content select=".min-length"></ng-content>
  </span>
  <span class="error-messages__text-danger" *ngIf="inputControl?.errors?.['pattern']">
    <ng-content select=".pattern"></ng-content>
  </span>
  <span class="error-messages__text-danger" *ngIf="inputControl?.errors?.['matching']">
    <ng-content select=".match-password"></ng-content>
  </span>
</div>
