import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  constructor(
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService
  ) {}

  getToken(): string | null {
    return this.getAutoLoginValue()
      ? this.localStorageService.get(environment.tokenKeyStorage)
      : this.sessionStorageService.get(environment.tokenKeyStorage);
  }

  setToken(value: string, autoLogin: boolean): void {
    autoLogin
      ? this.localStorageService.set(environment.tokenKeyStorage, value)
      : this.sessionStorageService.set(environment.tokenKeyStorage, value);
  }

  removeItem(value: string): void {
    this.localStorageService.remove(value);
    this.sessionStorageService.remove(value);
  }

  getAutoLogin(): string | null {
    return this.localStorageService.get(environment.autoLoginKey);
  }

  setAutoLogin(autoLogin: boolean): void {
    this.localStorageService.set(environment.autoLoginKey, JSON.stringify(autoLogin));
  }

  clearAuthStorage(): void {
    this.localStorageService.clear();
    this.sessionStorageService.clear();
  }

  getRefreshToken(): string | null {
    return this.getAutoLoginValue()
      ? this.localStorageService.get(environment.refreshTokenKeyStorage)
      : this.sessionStorageService.get(environment.refreshTokenKeyStorage);
  }

  setRefreshToken(value: string, autoLogin: boolean): void {
    autoLogin
      ? this.localStorageService.set(environment.refreshTokenKeyStorage, value)
      : this.sessionStorageService.set(environment.refreshTokenKeyStorage, value);
  }

  private getAutoLoginValue(): boolean | null {
    const autoLogin: string | null = this.getAutoLogin();
    if (autoLogin) {
      return JSON.parse(autoLogin);
    }
    return null;
  }
}
