import { OnDestroy } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { DialogBelonging } from '@costlydeveloper/ngx-awesome-popup';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  title!: string;
  description!: string;

  private readonly destroy$: Subject<null> = new Subject();

  constructor(@Inject('dialogBelonging') public dialogBelonging: DialogBelonging) {}

  ngOnInit(): void {
    this.initVars();
    this.subscribeToButtonClicks();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private initVars(): void {
    this.title = this.dialogBelonging.customData.title;
    this.description = this.dialogBelonging.customData.description;
  }

  private subscribeToButtonClicks(): void {
    this.dialogBelonging.eventsController.onButtonClick$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_Button) => {
        if (_Button.ID === 'confirm') {
          this.dialogBelonging.eventsController.close(true);
        } else if (_Button.ID === 'cancel') {
          this.dialogBelonging.eventsController.close();
        }
      });
  }
}
