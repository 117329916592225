import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AuthFormWrapperComponent } from './auth-form-wrapper/auth-form-wrapper.component';
import { FieldMessageWrapperComponent } from './field-message-wrapper/field-message-wrapper.component';
import { ToastrNotificationComponent } from './toastr-notification/toastr-notification.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TableComponent } from './table/table.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxPopperModule } from 'ngx-popper';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { TrailerDialogComponent } from './trailer-dialog/trailer-dialog.component';
import { DatepickerModule } from 'ng2-datepicker';
import { TrailerUploadImageComponent } from './trailer-dialog/trailer-upload-image/trailer-upload-image.component';
import { DndDirective } from '../core/directives/dnd.directive';
import { OnlyNumberDirective } from '../core/directives/only-number.directive';
import { NoteSectionComponent } from './note-section/note-section.component';
import { ActionTableComponent } from './action-table/action-table.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { ClientDialogComponent } from './client-dialog/client-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPopperModule,
    DatepickerModule,
  ],
  declarations: [
    AuthFormWrapperComponent,
    FieldMessageWrapperComponent,
    DndDirective,
    OnlyNumberDirective,
    ToastrNotificationComponent,
    SidebarComponent,
    NavbarComponent,
    TableComponent,
    PaginationComponent,
    ConfirmModalComponent,
    TrailerDialogComponent,
    TrailerUploadImageComponent,
    NoteSectionComponent,
    ActionTableComponent,
    TermsAndConditionComponent,
    ClientDialogComponent,
    SpinnerComponent,
  ],
  exports: [
    AuthFormWrapperComponent,
    FieldMessageWrapperComponent,
    ToastrNotificationComponent,
    SidebarComponent,
    NavbarComponent,
    TableComponent,
    PaginationComponent,
    OnlyNumberDirective,
    NoteSectionComponent,
    ActionTableComponent,
    TermsAndConditionComponent,
    SpinnerComponent,
  ],
})
export class SharedComponentsModule {}
