import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LOGOUT_REDIRECT } from '../../constants/auth-constants';
import { Auth, LoginPOST } from '../../models/login.model';
import { AuthStorageService } from '../auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private endpoint = environment.api;

  constructor(
    private http: HttpClient,
    private authStorageService: AuthStorageService,
    private router: Router
  ) {}

  isAuthenticated(): boolean {
    const isTokenExist = this.authStorageService.getToken() ? true : false;

    return isTokenExist;
  }

  login(userLogin: LoginPOST): Observable<Auth> {
    const url: string = `${this.endpoint}admin/login`;

    return this.http.post<Auth>(url, userLogin);
  }

  forgotPassword(email: string): Observable<null> {
    const url: string = `${this.endpoint}admin/forgot-password?email=${email}`;

    return this.http.post<null>(url, null);
  }

  newPassword(newPassword: string, token: string): Observable<null> {
    const url: string = `${this.endpoint}admin/new-password`;

    return this.http.post<null>(url, { newPassword, token });
  }

  refreshToken(): Observable<Auth> {
    const refreshToken: string | null = this.authStorageService.getRefreshToken();
    const url: string = `${this.endpoint}admin/refresh?token=${refreshToken}`;

    return this.http.post<Auth>(url, null);
  }

  logout(): Observable<null> {
    const url: string = `${this.endpoint}admin/logout`;

    return this.http.post<null>(url, null);
  }

  logoutMode(): void {
    this.authStorageService.clearAuthStorage();
    this.router.navigate([LOGOUT_REDIRECT]);
  }
}
