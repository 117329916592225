import { getFormattedDateTimeForServer } from './../../../../../../core/utils/formatted-date';
import { Router } from '@angular/router';
import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  DialogBelonging,
  DialogInitializer,
  DialogLayoutDisplay,
} from '@costlydeveloper/ngx-awesome-popup';
import { Subject, takeUntil } from 'rxjs';
import { TrailerFull } from 'src/app/core/models/trailer-full.model';
import { OrdersApiService } from 'src/app/core/services/api/orders-api.service';
import { NotificationService } from 'src/app/core/services/toastr-notification.service';
import { SendEmailDialogComponent } from '../../send-email-dialog/send-email-dialog.component';
import { TimeZoneHelperService } from 'src/app/core/services/timezone.helper';
import moment from 'moment';

@Component({
  selector: 'app-reservation-details-dialog',
  templateUrl: './reservation-details-dialog.component.html',
  styleUrls: ['./reservation-details-dialog.component.scss'],
})
export class ReservationDetailsDialogComponent implements OnInit, OnDestroy {
  trailer!: TrailerFull | null;
  startDate!: Date;
  endDate!: Date;
  timeRange = [9, 10, 11, 12, 13, 14, 15, 16, 17];

  @ViewChild('popperAction') popperAction: any;

  private readonly destroy$: Subject<null> = new Subject();

  constructor(
    @Inject('dialogBelonging') public dialogBelonging: DialogBelonging,
    private router: Router,
    private ordersService: OrdersApiService,
    private toastr: NotificationService,
    private timeZoneHelperService: TimeZoneHelperService
  ) {}

  ngOnInit(): void {
    this.prepareToShowTrailer();
    this.startDate = moment(this.startDate).set('hours', 9).set('minutes', 0).toDate();
    this.endDate = moment(this.endDate).set('hours', 9).set('minutes', 0).add('days', 1).toDate()
  }

  async openDialogToSendLinkToEmail(): Promise<void> {
    const dialogPopup = new DialogInitializer(SendEmailDialogComponent);

    dialogPopup.setCustomData({
      dimensions: this.trailer?.dimensions,
      startDate: this.startDate,
      endDate: this.endDate,
    });

    dialogPopup.setConfig({
      width: '580px',
      layoutType: DialogLayoutDisplay.NONE,
      buttonPosition: 'right',
    });

    dialogPopup.openDialog$().subscribe((resp) => {
      if (resp.payload) {
        this.toastr.success('Successfully sent link to email!');
        this.closePopup();
      }
    });
  }

  closePopup(): void {
    this.dialogBelonging.eventsController.close();
  }

  navigateToBookingPage(): void {
    this.orderTrailer();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private orderTrailer(): void {
    const startRentDate = getFormattedDateTimeForServer(this.startDate);
    const endRentDate = getFormattedDateTimeForServer(this.endDate);
    const timezone = this.timeZoneHelperService.getTimeZone();
    if (this.trailer) {
      this.ordersService
        .chooseOrderTrailer(startRentDate, endRentDate, this.trailer.dimensions, timezone)
        .pipe(takeUntil(this.destroy$))
        .subscribe((order) => {
          this.router.navigate(['/inventory/book-trailer', order.id]);
          this.closePopup();
        });
    }
  }

  private prepareToShowTrailer(): void {
    this.trailer = this.dialogBelonging.customData.trailer;
    this.startDate = this.dialogBelonging.customData.startDate;
    this.endDate = this.dialogBelonging.customData.endDate;
  }

  formatHours(hour: number) {
    return moment(new Date()).set('hour', hour).set('minutes', 0).format('h:mm a');
  }

  setStartDateTime(hour: number) {
    this.startDate = moment(this.startDate)
      .set('hour', hour)
      .set('minutes', 0)
      .set('seconds', 0)
      .toDate();
    this.popperAction.hide();
  }

  isActiveTime(hour: number): boolean {
    const startHour = moment(this.startDate).get('hour');
    return startHour === hour;
  }
}
