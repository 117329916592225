<div class="navbar">
  <div class="navbar__title">{{ getTitle() | titlecase }}</div>
  <div class="navbar__actions">
    <div class="navbar__user"
         [popper]="popperAction"
         [popperPlacement]="'bottom'"
         [popperHideOnClickOutside]="true"
         [popperDisableAnimation]="true"
         [popperTrigger]="'click'"
         [popperApplyArrowClass]="'shared-table__arrow'"
         [popperPositionFixed]="true">
      <p class="navbar__user-name">{{ user?.firstName }} {{ user?.lastName }}</p>
      <img src="/assets/images/user-photo.png" alt="User"/>
    </div>
  </div>
</div>

<popper-content #popperAction>
  <div class="popper form">
    <div class="popper__list popper__list--action">
      <div class="popper__action-item" (click)="logout()">
        Logout
      </div>
    </div>
  </div>
</popper-content>
