<div class="auth-form-wrapper">
  <div class="row">
    <div class="col-6 auth-form-wrapper__logo-background"></div>
    <div class="col-6">
      <div class="auth-form-wrapper__form">
        <p class="auth-form-wrapper__title" [class.auth-form-wrapper__title--mb]="!description">
          {{ title }}
        </p>
        <p class="auth-form-wrapper__description" *ngIf="description">{{ description }}</p>
        <ng-content></ng-content>
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
