import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AppearanceAnimation,
  ConfirmBoxInitializer, DialogInitializer,
  DialogLayoutDisplay,
  DisappearanceAnimation,
} from '@costlydeveloper/ngx-awesome-popup';
import { OfficeTrailerDimension } from 'src/app/core/models/office-trailer-dimension.model';
import { OfficeDimensions } from 'src/app/core/models/office-trailer.model';
import { PriceList } from 'src/app/core/models/price-list.model';
import { Quote } from 'src/app/core/models/quote.model';
import { TableData } from 'src/app/module/dashboard/components/quote-list/create-quote-dialog/create-quote-dialog.component';
import {
  CalendarTrailersDialogComponent
} from '../../module/dashboard/components/quote-list/calendar-trailers-dialog/calendar-trailers-dialog.component';

interface DeliveryData {
  description: string;
  quantity: number;
  rate: number;
  term: string;
}

interface FurnitureData {
  id: number;
  furniture: string;
  furnitureId: string;
  quantity: number;
  rate: number;
  term: number;
  showDelete: boolean;
}

interface EquipmentData {
  id: number;
  numId: string;
  description: string;
  quantity: number;
  rate: number;
  term: number;
  showDelete: boolean;
  friendlyName?: string;
}

@Component({
  selector: 'app-action-table',
  templateUrl: './action-table.component.html',
  styleUrls: ['./action-table.component.scss'],
})
export class ActionTableComponent implements OnChanges {
  public equipmentData: EquipmentData[] = [];
  public furnitureData: FurnitureData[] = [];
  public deliveryData!: DeliveryData;

  private terms!: number;

  @Input() class!: string;
  @Input() headers!: string[];
  @Input() isQuote!: boolean;
  @Input() isContract!: boolean;
  @Input() equipments: OfficeDimensions[] = [];
  @Input() furniture: PriceList[] | undefined = [];
  @Input() trailerDimensions: OfficeTrailerDimension[] | undefined = [];
  @Input() quote!: Quote;

  @Output() tableData = new EventEmitter<TableData>();
  @Output() termData = new EventEmitter<number>();

  public onChangeData(term?: number): void {
    if (this.deliveryData.quantity > 2 || this.deliveryData.quantity < 0) {
      this.deliveryData.quantity = 2;
    }

    const data = {
      trailers: this.equipmentData.map((v, i) => ({
        rentPrice: v.rate,
        quantity: v.quantity,
        ...(this.isQuote
          ? { dimensions: v.description }
          : { officeTrailerId: this.getOfficeTrailerId(v) }),
      })),
      furniture: this.furnitureData.map((v, i) => ({
        furnitureId: v.furnitureId,
        quantity: v.quantity,
        rentPrice: v.rate,
      })),
      deliveryPrice: this.deliveryData?.rate,
      deliveryQuantity: this.deliveryData?.quantity,
    };

    if (term !== undefined && term !== null) {
      this.terms = term;
      this.equipmentData = this.equipmentData.map((v) => {
        v.term = term;
        return v;
      });
      this.furnitureData = this.furnitureData.map((v) => {
        v.term = term;
        return v;
      });
      this.termData.emit(term);
    }

    this.tableData.emit(data);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['quote']) {
      if (this.quote?.trailers?.length) {
        this.equipmentData = this.quote?.trailers.map((v, i) => ({
          id: i,
          numId: '',
          description: v?.dimensionsData?.dimensions,
          quantity: v?.quantity || 0,
          rate: v?.rentPrice || 0,
          term: this.quote?.terms || 1,
          showDelete: false,
        }));
      } else {
        this.equipmentData = [
          {
            id: 0,
            numId: '',
            description: this.quote?.dimensionsData?.dimensions,
            quantity: 1,
            rate: 0,
            term: this.quote?.terms || 1,
            showDelete: false,
          },
        ];
      }

      if (this.quote?.furnitureItems?.length) {
        this.furnitureData = this.quote?.furnitureItems.map((v, i) => ({
          id: i,
          furniture: v?.description,
          furnitureId: v?.furnitureId,
          quantity: v?.quantity,
          rate: v?.pricePerUnit,
          term: this.quote?.terms || 1,
          showDelete: false,
        }));
      } else {
        this.furnitureData = [
          {
            id: 0,
            furniture: '',
            furnitureId: '',
            quantity: 0,
            rate: 0,
            term: this.quote?.terms || 1,
            showDelete: false,
          },
        ];
      }

      this.deliveryData = {
        description: this.quote?.deliveryCity ? (`Delivery to ${this.quote?.deliveryCity}`) : 'Delivery by client',
        quantity: this.quote?.deliveryCity ? (this.quote?.deliveryQuantity || 2) : 0,
        rate: this.quote?.deliveryPrice || 0,
        term: 'per load',
      };

      this.onChangeData();
    }
  }

  public getOfficeTrailerId(data: EquipmentData): string {``
    const trailer = this.trailerDimensions?.find(
      (v) => v.dimensionsData.dimensions === data.description
    );
    const trailerId = trailer?.officeTrailers?.find((v) => v.unitNumber === data.numId)?.id;
    return trailerId || '';
  }

  public getNumIdsForTrailer(index: number): string[] {
    const trailers = this.trailerDimensions?.find(
      (v) => v.dimensionsData.dimensions === this.equipmentData[index]?.description
    );
    const allNumIds = new Set(
      this.equipmentData
        .filter((v) => this.equipmentData[index].numId !== v.numId)
        .map((v) => v.numId)
    );
    const currentNumIds = new Set(trailers?.officeTrailers?.map((v) => v.unitNumber) || []);
    for (const item of allNumIds) {
      currentNumIds.delete(item);
    }
    const uniqueNumIds = [...currentNumIds];

    return uniqueNumIds;
  }

  public showDelete(index: number): void {
    this.equipmentData[index].showDelete = true;
  }

  public hideDelete(index: number): void {
    this.equipmentData[index].showDelete = false;
  }

  public showFurnitureDelete(index: number): void {
    this.furnitureData[index].showDelete = true;
  }

  public hideFurnitureDelete(index: number): void {
    this.furnitureData[index].showDelete = false;
  }

  public addEquipment(): void {
    this.equipmentData.push({
      id: this.equipmentData.length,
      numId: '',
      description: this.equipments[0]?.dimensions,
      quantity: 0,
      rate: 0,
      term: this.terms,
      showDelete: false,
    });
    this.onChangeData();
  }

  public addFurniture(): void {
    this.furnitureData.push({
      id: this.furnitureData.length,
      furniture: this.quote.furniture ? this.quote?.furnitureItems[0]?.description : '',
      furnitureId: this.quote.furniture ? this.quote?.furnitureItems[0]?.furnitureId : '',
      quantity: this.quote.furniture ? this.quote?.furnitureItems[0]?.quantity || 0 : 0,
      rate: this.quote.furniture ? this.quote?.furnitureItems[0]?.pricePerUnit || 0 : 0,
      term: this.terms,
      showDelete: false,
    });
    this.onChangeData();
  }

  public getGeneralSubTotal(): number {
    return this.getTotalEquipmentSum() + this.getTotalFurnitureSum() + this.getTotalDeliverySum();
  }

  public getGeneralGST(): number {
    return this.getGeneralSubTotal() * 0.05;
  }

  public getGeneralTotal(): number {
    return this.getGeneralSubTotal() + this.getGeneralGST();
  }

  public getTotalFurnitureSum(): number {
    return this.furnitureData.reduce((sum, item) => sum + item.rate * item.quantity * item.term, 0);
  }

  public getTotalFurniture(index: number): number {
    return (
      this.furnitureData[index].rate *
      this.furnitureData[index].quantity *
      this.furnitureData[index].term
    );
  }

  public getTotalEquipment(index: number): number {
    return (
      this.equipmentData[index].rate *
      this.equipmentData[index].quantity *
      this.equipmentData[index].term
    );
  }

  public getTotalEquipmentSum(): number {
    return this.equipmentData.reduce((sum, item) => sum + item.rate * item.quantity * item.term, 0);
  }

  public getTotalDeliverySum(): number {
    return this.deliveryData.quantity * this.deliveryData.rate;
  }

  public async openDialogToDeleteColumn(
    id: number,
    type: 'EQUIPMENT' | 'FURNITURE'
  ): Promise<void> {
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setMessage(`Are you sure you want to delete the column?`);
    confirmBox.setButtonLabels('Delete', 'Cancel');

    confirmBox.setConfig({
      layoutType: DialogLayoutDisplay.DANGER,
      animationIn: AppearanceAnimation.BOUNCE_IN,
      animationOut: DisappearanceAnimation.BOUNCE_OUT,
      buttonPosition: 'center',
      disableIcon: true,
    });

    confirmBox.openConfirmBox$().subscribe((resp) => {
      if (resp.success) {
        if (type === 'EQUIPMENT') {
          this.equipmentData = this.equipmentData.filter((item) => item.id !== id);
        }
        if (type === 'FURNITURE') {
          this.furnitureData = this.furnitureData.filter((item) => item.id !== id);
        }
        this.onChangeData();
      }
    });
  }

  openModalForOfficeTrailers(index: number) {
    const dialogPopup = new DialogInitializer(CalendarTrailersDialogComponent);

    dialogPopup.setCustomData({
      title: 'Office Trailers'
    })

    dialogPopup.setConfig({
      width: '100%',
      layoutType: DialogLayoutDisplay.NONE,
      buttonPosition: 'right',
    });

    dialogPopup.openDialog$().subscribe((resp) => {
      if (resp.payload) {
        this.equipmentData[index].numId = resp.payload.unitNumber;
        this.equipmentData[index].description = resp.payload.dimensionsData?.dimensions;
        this.equipmentData[index].friendlyName = resp.payload.dimensionsData?.friendlyName;
        this.onChangeData();
      }
    });
  }
}
