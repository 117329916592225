import { UsedTrailer } from 'src/app/core/models/used-trailer.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsedTrailerStateManagementService {
  private usedTrailerCreationSubject: Subject<UsedTrailer> = new Subject<UsedTrailer>();

  constructor() {}

  public getUsedTrailerCreationEvent(): Subject<UsedTrailer> {
    return this.usedTrailerCreationSubject;
  }

  public sendUsedTrailerCreationEvent(usedTrailer: UsedTrailer): void {
    this.usedTrailerCreationSubject.next(usedTrailer);
  }
}
