<div class="sidebar">
  <div class="sidebar__logo">
    <img src="/assets/images/logo.svg" class="sidebar__logo-image" routerLink="/main-dashboard" />
  </div>
  <div class="sidebar__wrapper">
    <ul class="sidebar__nav">
      <li
        *ngFor="let menuItem of menuItems"
        class="sidebar__nav-item"
        [class.sidebar__nav-item--active]="activeLink.includes(menuItem.path)"
        (click)="navigateToMenuItem(menuItem.path)"
      >
        <a class="sidebar__link">
          <i
            [class]="menuItem.icon"
            class="sidebar__icon icon"
            [class.sidebar__icon--active]="activeLink.includes(menuItem.path)"
          ></i>
<!--          <svg *ngIf="isFlatDeck(menuItem)" width="32" height="25" viewBox="0 0 512 325" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <g clip-path="url(#clip0_3658_18546)">-->
<!--              <path d="M444.237 147.265H361.413C361.413 163.899 347.929 177.383 331.295 177.383H271.06C271.06 139.957 240.721 109.618 203.295 109.618C189.364 109.618 176.416 113.822 165.648 121.029C154.88 113.821 141.932 109.618 128.001 109.618C90.575 109.618 60.236 139.957 60.236 177.383H30.118C13.484 177.383 0 163.899 0 147.265V117.147V94H26.502H361.413L361.412 117.147H496.941C505.258 117.147 512 123.889 512 132.206C512 140.523 505.258 147.265 496.941 147.265H474.353V169.853C474.353 178.17 467.611 184.912 459.294 184.912C450.977 184.912 444.235 178.17 444.235 169.853V147.265H444.237ZM165.649 140.496C175.215 130.734 188.548 124.677 203.296 124.677C232.404 124.677 256.002 148.274 256.002 177.383C256.002 206.491 232.405 230.089 203.296 230.089C188.548 230.089 175.215 224.032 165.649 214.27C156.083 224.032 142.75 230.089 128.002 230.089C98.894 230.089 75.296 206.492 75.296 177.383C75.296 148.275 98.893 124.677 128.002 124.677C142.75 124.676 156.083 130.733 165.649 140.496ZM128.002 199.971C140.477 199.971 150.59 189.858 150.59 177.383C150.59 164.908 140.477 154.795 128.002 154.795C115.527 154.795 105.414 164.908 105.414 177.383C105.414 189.858 115.527 199.971 128.002 199.971ZM203.296 199.971C215.771 199.971 225.884 189.858 225.884 177.383C225.884 164.908 215.771 154.795 203.296 154.795C190.821 154.795 180.708 164.908 180.708 177.383C180.708 189.858 190.821 199.971 203.296 199.971Z" fill="black"/>-->
<!--            </g>-->
<!--            <defs>-->
<!--              <clipPath id="clip0_3658_18546">-->
<!--                <rect width="512" height="325" fill="white"/>-->
<!--              </clipPath>-->
<!--            </defs>-->
<!--          </svg>-->
          <p
            class="sidebar__title title"
            [class.sidebar__title--active]="activeLink.includes(menuItem.path)"
          >
            {{ menuItem.title }}
          </p>
        </a>
      </li>
    </ul>
  </div>
</div>
