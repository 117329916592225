import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneHelperService {
  public getTimeZone() {
    const now = new Date();

    const timezoneOffsetMinutes = now.getTimezoneOffset();

    const timezoneOffsetHours = timezoneOffsetMinutes / 60;

    const timezoneOffsetHoursPositive = -timezoneOffsetHours;

    return timezoneOffsetHoursPositive;
  }
}
