import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/main-dashboard', title: 'Main dashboard', icon: 'icon-overview' },
  // { path: '/inventory', title: 'Flat Deck', icon: 'icon-inventory' },
  { path: '/inventory', title: 'Inventory', icon: 'icon-inventory' },
  { path: '/client-list', title: 'Client list', icon: 'icon-contacts' },
  { path: '/order-history', title: 'Order history', icon: 'icon-order-history' },
  // { path: '/office-trailers', title: 'Office Trailers', icon: 'icon-office-trailer' },
  // { path: '/quote-list', title: 'Quote List', icon: 'icon-quotes' },
  // { path: '/contracts', title: 'Contracts', icon: 'icon-contracts' },
  { path: '/reports', title: 'Reports', icon: 'icon-reports' },
  { path: '/city-deliveries', title: 'City Deliveries', icon: 'icon-city' },
  { path: '/sale-used-trailers', title: 'Sale', icon: 'icon-sale' },
  { path: '/price-list', title: 'Price List', icon: 'icon-tag-solid' },
  { path: '/promo-code', title: 'Promo code', icon: 'icon-inventory' },
  { path: '/settings', title: 'Settings', icon: 'icon-settings' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: RouteInfo[] = [];
  activeLink = '';

  private readonly destroy$: Subject<null> = new Subject();

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.handleActiveRoute();
  }

  navigateToMenuItem(path: string): void {
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private handleActiveRoute(): void {
    this.activeLink = this.router.url;
    this.menuItems = ROUTES;
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: any) => {
        this.activeLink = event.url;
      });
  }

  isFlatDeck(menuItem: RouteInfo) {
    return menuItem.title === 'Flat Deck';
  }
}
