<div class="wrapper">
  <div class="wrapper__sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="wrapper__main-panel">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <app-spinner></app-spinner>
  </div>
</div>
