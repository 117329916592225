import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientData, ClientOffice } from '../../models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientListApiService {
  private endpoint = environment.api;

  constructor(private http: HttpClient) {}

  getClients(
    limit: number,
    offset: number,
    sortBy: string | undefined,
    sortOrder: 'ASCENDING' | 'DESCENDING' | undefined
  ): Observable<ClientData> {
    const url: string = `${this.endpoint}admin/clients/search?limit=${limit}&offset=${offset}`;

    let params = new HttpParams();

    if (sortBy) {
      params = params.append('sortBy', sortBy);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    return this.http.post<ClientData>(url, null, { params });
  }

  getOfficeClients(
    limit: number,
    offset: number,
    sortBy: string | undefined,
    sortOrder: 'ASCENDING' | 'DESCENDING' | undefined
  ): Observable<ClientData> {
    const url: string = `${this.endpoint}admin/trailers/office/clients/search?limit=${limit}&offset=${offset}`;

    let params = new HttpParams();

    if (sortBy) {
      params = params.append('sortBy', sortBy);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    return this.http.post<ClientData>(url, null, { params });
  }

  createOfficeClient(client: ClientOffice): Observable<ClientOffice> {
    const url: string = `${this.endpoint}admin/trailers/office/clients`;

    return this.http.post<ClientOffice>(url, client);
  }

  editOfficeClient(clientId: string, client: ClientOffice): Observable<ClientOffice> {
    const url: string = `${this.endpoint}admin/trailers/office/clients/${clientId}`;

    return this.http.put<ClientOffice>(url, client);
  }

  deleteOfficeClient(clientId: string): Observable<ClientOffice> {
    const url: string = `${this.endpoint}admin/trailers/office/clients/${clientId}`;

    return this.http.delete<ClientOffice>(url);
  }

  sendCreditApplication(clientId: string | undefined): Observable<ClientOffice> {
    const url: string = `${this.endpoint}admin/trailers/office/clients/${clientId}/credit-application/send`;

    return this.http.patch<ClientOffice>(url, null);
  }

  getNextNumId(): Observable<{numId: number}> {
    const url: string = `${this.endpoint}admin/trailers/office/clients/num-ids/next`;

    return this.http.get<{numId: number}>(url);
  }
}
