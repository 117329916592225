import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogBelonging } from '@costlydeveloper/ngx-awesome-popup';
import { DatepickerOptions } from 'ng2-datepicker';
import { Subject, takeUntil } from 'rxjs';
import { TrailerFull, TrailerPOST } from 'src/app/core/models/trailer-full.model';
import { TrailerType } from 'src/app/core/models/trailer-type.model';
import { UsedTrailer, UsedTrailerPOST } from 'src/app/core/models/used-trailer.model';
import { TrailersApiService } from 'src/app/core/services/api/trailers-api.service';
import { getFormattedDateForServer } from 'src/app/core/utils/formatted-date';
import { getYears } from 'src/app/core/utils/years-generator';
import { Image } from 'src/app/core/models/accessory.model';
import { UsedTrailersApiService } from 'src/app/core/services/api/used-trailers-api.service';
import { UsedTrailerStateManagementService } from 'src/app/core/services/api/used-trailer-state-management.service';
import { TrailerStateManagementService } from 'src/app/core/services/api/trailer-state-management.service';
import {
  GroupedOfficeTrailerDimension,
  OfficeTrailer,
  OfficeTrailerPost,
} from 'src/app/core/models/office-trailer.model';
import { OfficeTrailersApiService } from 'src/app/core/services/api/office-trailers-api.service';
import { OfficeTrailerStateManagementService } from 'src/app/core/services/api/office-trailer-state-management.service';

interface TrailerProperty {
  id: number;
  property: string;
  type: 'string' | 'number' | 'dropdown' | 'date' | 'textarea';
  value: number | string | boolean | any;
  title: string;
  dropdown?: string[] | number[] | any[];
  required?: boolean;
}

@Component({
  selector: 'app-trailer-dialog',
  templateUrl: './trailer-dialog.component.html',
  styleUrls: ['./trailer-dialog.component.scss'],
})
export class TrailerDialogComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  title!: string;
  trailer!: TrailerFull;
  usedTrailer!: UsedTrailer;
  officeTrailer!: OfficeTrailer;
  isEditMode = false;
  isCreationMode = false;
  trailerProperties: TrailerProperty[] = [];
  trailerTypes = ['ENCLOSED', 'FLAT_DECK', 'DUMP'];
  statuses = ['AVAILABLE', 'SOLD', 'ON_HOLD'];
  types: TrailerType[] = [];
  dimensions!: string;
  formData: FormData = new FormData();
  formDataMainImage: FormData = new FormData();
  openDetails = false;
  removableImages: string[] = [];
  mainImage!: Image | null;
  images: Image[] | null = [];
  isFiles = false;
  officeFriendlyTypes: string[] = [];
  groupedOfficeTypes: GroupedOfficeTrailerDimension[] = [];

  options: DatepickerOptions = {
    placeholder: 'Choose Date', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'M/d/yyyy', // date format to display in input
    formatTitle: 'LLLL yyyy',
    formatDays: 'EEEEE',
    firstCalendarDay: 1, // 0 - Sunday, 1 - Monday
    inputClass: 'datepicker-default__input', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
  };

  private readonly destroy$: Subject<null> = new Subject();

  constructor(
    @Inject('dialogBelonging') public dialogBelonging: DialogBelonging,
    private fb: FormBuilder,
    private trailersService: TrailersApiService,
    private usedTrailersService: UsedTrailersApiService,
    private officeTrailersService: OfficeTrailersApiService,
    private usedTrailerStateManagementService: UsedTrailerStateManagementService,
    private trailerStateManagementService: TrailerStateManagementService,
    private officeTrailerStateManagementService: OfficeTrailerStateManagementService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setTitle();
    this.prepareToShowTrailerTypes();
  }

  save(): void {
    if (this.dialogBelonging.customData?.isUsedTrailer) {
      this.saveUsedTrailer();
    }
    if (this.dialogBelonging.customData?.isOfficeTrailer) {
      this.saveOfficeTrailer();
    }
    if (
      !this.dialogBelonging.customData?.isUsedTrailer &&
      !this.dialogBelonging.customData?.isOfficeTrailer
    ) {
      this.saveTrailer();
    }
  }

  updateMainImage(file: File): void {
    this.formDataMainImage.delete('image');
    this.formDataMainImage.append('image', file);
  }

  addDeleteImagesToList(event: string[]): void {
    this.removableImages = event;
  }

  changeImageDetails(openDetails: boolean): void {
    this.openDetails = openDetails;
  }

  updateTrailerFiles(files: File[]): void {
    this.formData.delete('images');
    this.formDataMainImage.delete('image');
    this.formDataMainImage.append('image', files[0]);
    this.isFiles = !!files?.length;
    for (const file of files) {
      this.formData.append('images', file);
    }
  }

  changeDropdownItem(event: Event, property: string): void {
    const value = (event.target as HTMLInputElement).value;
    if (property === 'trailerType') {
      if (this.dialogBelonging.customData?.isOfficeTrailer) {
        this.handleOfficeTrailerType(value);
      }

      if (!this.dialogBelonging.customData?.isOfficeTrailer) {
        this.handleTrailerType(value);
      }
    }
    if (property === 'size') {
      this.handleTrailerSize(value);
    }
  }

  enableEdit(): void {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode && this.trailer && !this.isCreationMode) {
      this.status?.disable();
    }
    this.title = this.isEditMode ? 'Edit Trailer' : 'Details';
  }

  getTrailerTypeValue(value: string | boolean | number): string | number {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (value === 'FLAT_DECK') {
      return 'Flat Deck Trailers';
    }

    if (value === 'DUMP') {
      return 'Dump Trailers';
    }

    if (value === 'ENCLOSED') {
      return 'Enclosed Trailers';
    }

    if (value === 'ON_HOLD') {
      return 'On Hold';
    }

    return typeof value === 'string'
      ? value?.toLowerCase()?.replace(/\b\w/g, (s) => s?.toUpperCase())
      : value;
  }

  closePopup(): void {
    this.dialogBelonging.eventsController.close();
  }

  get numId(): AbstractControl | null {
    return this.form.get('numId');
  }

  get dayOfSale(): AbstractControl | null {
    return this.form.get('dayOfSale');
  }

  get isRegistered(): AbstractControl | null {
    return this.form.get('isRegistered');
  }

  get plateNumber(): AbstractControl | null {
    return this.form.get('plateNumber');
  }

  get vin(): AbstractControl | null {
    return this.form.get('vin');
  }

  get year(): AbstractControl | null {
    return this.form.get('year');
  }

  get manufacturer(): AbstractControl | null {
    return this.form.get('manufacturer');
  }

  get loadingCapacity(): AbstractControl | null {
    return this.form.get('loadingCapacity');
  }

  get size(): AbstractControl | null {
    return this.form.get('size');
  }

  get trailerType(): AbstractControl | null {
    return this.form.get('trailerType');
  }

  get comments(): AbstractControl | null {
    return this.form.get('comments');
  }

  get inspectionExpire(): AbstractControl | null {
    return this.form.get('inspectionExpire');
  }

  get priceOfPurchasing(): AbstractControl | null {
    return this.form.get('priceOfPurchasing');
  }

  get dateOfPurchase(): AbstractControl | null {
    return this.form.get('dateOfPurchase');
  }

  get salePrice(): AbstractControl | null {
    return this.form.get('salePrice');
  }

  get status(): AbstractControl | null {
    return this.form.get('status');
  }

  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  get type(): AbstractControl | null {
    return this.form.get('type');
  }

  get price(): AbstractControl | null {
    return this.form.get('price');
  }

  get buildingSize(): AbstractControl | null {
    return this.form.get('buildingSize');
  }

  get hitch(): AbstractControl | null {
    return this.form.get('hitch');
  }

  get weight(): AbstractControl | null {
    return this.form.get('weight');
  }

  get unitNumber(): AbstractControl | null {
    return this.form.get('unitNumber');
  }

  get occupancy(): AbstractControl | null {
    return this.form.get('occupancy');
  }

  get power(): AbstractControl | null {
    return this.form.get('power');
  }

  get heat(): AbstractControl | null {
    return this.form.get('heat');
  }

  get heaterType(): AbstractControl | null {
    return this.form.get('heaterType');
  }

  get airConditioning(): AbstractControl | null {
    return this.form.get('airConditioning');
  }

  get anyOfficeSpaces(): AbstractControl | null {
    return this.form.get('anyOfficeSpaces');
  }

  get sellingPrice(): AbstractControl | null {
    return this.form.get('sellingPrice');
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private saveTrailer(): void {
    const currentTrailerType = this.types.find(
      (type) => type.trailerType === this.trailerType?.value
    );
    let size = '';
    if (currentTrailerType) {
      const activeCapacity = currentTrailerType.trailers.find(
        (trailer) => `${trailer.sizeName} (${trailer.loadingCapacity}lbs)` === this.size?.value
      );
      size = activeCapacity?.size || '';
      if (activeCapacity) {
        this.dimensions = activeCapacity?.dimensions;
      }
    }
    const trailer: TrailerPOST = {
      numId: this.numId?.value,
      dayOfSale: this.dayOfSale?.value
        ? getFormattedDateForServer(this.dayOfSale?.value)
        : undefined,
      comments: this.comments?.value,
      trailerType: this.trailerType?.value,
      size,
      capacity: this.loadingCapacity?.value,
      vin: this.vin?.value,
      dimensions: this.dimensions,
      plateNumber: this.plateNumber?.value,
      year: this.year?.value ? Number(this.year?.value) : undefined,
      salePrice: this.salePrice?.value ? Number(this.salePrice?.value) : undefined,
      dateOfPurchase: this.dateOfPurchase?.value
        ? getFormattedDateForServer(this.dateOfPurchase?.value)
        : undefined,
      priceOfPurchasing: this.priceOfPurchasing?.value
        ? Number(this.priceOfPurchasing?.value)
        : undefined,
      inspectionExpire: this.inspectionExpire?.value
        ? getFormattedDateForServer(this.inspectionExpire?.value)
        : undefined,
      status: this.status?.value,
      isRegistered: this.isRegistered?.value === 'Yes' ? true : false,
      manufacturer: this.manufacturer?.value,
      unitNumber: this.unitNumber?.value,
    };

    !this.isCreationMode ? this.editTrailer(trailer) : this.createTrailer(trailer);
  }

  private saveUsedTrailer(): void {
    const trailer: UsedTrailerPOST = {
      description: this.comments?.value,
      name: this.name?.value,
      size: this.size?.value,
      type: this.type?.value,
      price: Number(this.price?.value),
      weight: Number(this.weight?.value),
      buildingSize: this.buildingSize?.value,
      hitch: this.hitch?.value,
      occupancy: this.occupancy?.value,
      power: this.power?.value,
      heat: this.heat?.value,
    };

    !this.isCreationMode ? this.editUsedTrailer(trailer) : this.createUsedTrailer(trailer);
  }

  private updateImage(trailer: TrailerFull | UsedTrailer | OfficeTrailer): void {
    this.mainImage = trailer?.mainImage;
    this.images = trailer?.images;
  }

  private editUsedTrailer(trailer: UsedTrailerPOST): void {
    this.usedTrailersService
      .editUsedTrailer(this.trailer.id, trailer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedTrailer) => {
        const isExistImages = this.formData.get('images');
        const isExistImage = this.formDataMainImage.get('image');
        const isUploadImage = !!(isExistImages && isExistImage);
        if (isExistImages) {
          this.uploadImagesForUsedTrailer(updatedTrailer);
        } else if (!isExistImages && isExistImage) {
          this.uploadUsedMainImage(updatedTrailer);
        }
        if (this.removableImages.length) {
          this.removeUsedImages(updatedTrailer, isUploadImage);
        }
        if (!isExistImages && !this.removableImages.length) {
          this.dialogBelonging.eventsController.close(true);
        }
      });
  }

  private createUsedTrailer(trailer: UsedTrailerPOST): void {
    this.usedTrailersService
      .createUsedTrailer(trailer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((createdTrailer) => {
        const isExistImages = this.formData.get('images');
        if (isExistImages) {
          this.uploadImagesForUsedTrailer(createdTrailer);
        } else {
          this.usedTrailerStateManagementService.sendUsedTrailerCreationEvent(createdTrailer);
          this.dialogBelonging.eventsController.close(false);
        }
      });
  }

  private editTrailer(trailer: TrailerPOST): void {
    this.trailersService
      .editTrailer(this.trailer.id, trailer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedTrailer) => {
        const isExistImages = this.formData.get('images');
        const isExistImage = this.formDataMainImage.get('image');
        const isUploadImage = !!(isExistImages && isExistImage);
        if (isExistImages) {
          this.uploadImagesForTrailer(updatedTrailer);
        } else if (!isExistImages && isExistImage) {
          this.uploadMainImage(updatedTrailer);
        }
        if (this.removableImages.length) {
          this.removeImages(updatedTrailer, isUploadImage);
        }
        if (!isExistImages && !this.removableImages.length) {
          this.dialogBelonging.eventsController.close(true);
        }
      });
  }

  private createTrailer(trailer: TrailerPOST): void {
    this.trailersService
      .createTrailer(trailer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((createdTrailer) => {
        const isExistImages = this.formData.get('images');
        if (isExistImages) {
          this.uploadImagesForTrailer(createdTrailer);
        } else {
          this.trailerStateManagementService.sendTrailerCreationEvent(createdTrailer);
          this.dialogBelonging.eventsController.close(false);
        }
      });
  }

  private saveOfficeTrailer(): void {
    const currentTrailerType = this.groupedOfficeTypes.find(
      (type) => type.typeName === this.trailerType?.value
    );
    if (currentTrailerType) {
      const currentDimension = currentTrailerType.dimensions.find(
        (type) => type.sizeName === this.size?.value
      );
      this.dimensions = currentDimension?.dimensions || '';
    }
    const trailer: OfficeTrailerPost = {
      numId: this.numId?.value,
      dimensions: this.dimensions,
      manufacturer: this.manufacturer?.value,
      dateOfPurchase: this.dateOfPurchase?.value
        ? getFormattedDateForServer(this.dateOfPurchase?.value)
        : undefined,
      priceOfPurchasing: this.priceOfPurchasing?.value
        ? Number(this.priceOfPurchasing?.value)
        : undefined,
      dayOfSale: this.dayOfSale?.value
        ? getFormattedDateForServer(this.dayOfSale?.value)
        : undefined,
      salePrice: this.salePrice?.value ? Number(this.salePrice?.value) : undefined,
      year: this.year?.value ? Number(this.year?.value) : undefined,
      sellingPrice: this.sellingPrice?.value,
      vin: this.vin?.value,
      plateNumber: this.plateNumber?.value,
      isRegistered: this.isRegistered?.value === 'Yes' ? true : false,
      inspectionExpire: this.inspectionExpire?.value
        ? getFormattedDateForServer(this.inspectionExpire?.value)
        : undefined,
      unitNumber: this.unitNumber?.value,
      heaterType: this.heaterType?.value || null,
      airConditioning: this.airConditioning?.value === 'Yes' ? true : false,
      anyOfficeSpaces: this.anyOfficeSpaces?.value,
      comments: this.comments?.value,
    };

    !this.isCreationMode ? this.editOfficeTrailer(trailer) : this.createOfficeTrailer(trailer);
  }

  private editOfficeTrailer(trailer: OfficeTrailerPost): void {
    this.officeTrailersService
      .editOfficeTrailer(this.trailer.id, trailer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedTrailer) => {
        const isExistImages = this.formData.get('images');
        const isExistImage = this.formDataMainImage.get('image');
        const isUploadImage = !!(isExistImages && isExistImage);
        if (isExistImages) {
          this.uploadImagesForOfficeTrailer(updatedTrailer);
        } else if (!isExistImages && isExistImage) {
          this.uploadOfficeMainImage(updatedTrailer);
        }
        if (this.removableImages.length) {
          this.removeOfficeImages(updatedTrailer, isUploadImage);
        }
        if (!isExistImages && !this.removableImages.length) {
          this.dialogBelonging.eventsController.close(true);
        }
      });
  }

  private createOfficeTrailer(trailer: OfficeTrailerPost): void {
    this.officeTrailersService
      .createOfficeTrailer(trailer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((createdTrailer) => {
        const isExistImages = this.formData.get('images');
        if (isExistImages) {
          this.uploadImagesForOfficeTrailer(createdTrailer);
        } else {
          this.officeTrailerStateManagementService.sendOfficeTrailerCreationEvent(createdTrailer);
          this.dialogBelonging.eventsController.close(false);
        }
      });
  }

  private uploadImagesForOfficeTrailer(trailer: OfficeTrailer): void {
    this.officeTrailersService
      .addImagesToOfficeTrailer(trailer.id, this.formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.uploadOfficeMainImage(trailer);
      });
  }

  private uploadOfficeMainImage(trailer: OfficeTrailer): void {
    const isExistImage = this.formDataMainImage.get('image');
    if (isExistImage) {
      this.officeTrailersService
        .editMainOfficeTrailerImage(trailer.id, this.formDataMainImage)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.isCreationMode) {
            this.officeTrailerStateManagementService.sendOfficeTrailerCreationEvent(trailer);
            this.dialogBelonging.eventsController.close(false);
          } else {
            this.dialogBelonging.eventsController.close(true);
          }
        });
    } else {
      if (this.isCreationMode) {
        this.officeTrailerStateManagementService.sendOfficeTrailerCreationEvent(trailer);
        this.dialogBelonging.eventsController.close(false);
      } else {
        this.dialogBelonging.eventsController.close(true);
      }
    }
  }

  private uploadImagesForUsedTrailer(trailer: UsedTrailer): void {
    this.usedTrailersService
      .addImagesToUsedTrailer(trailer.id, this.formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.uploadUsedMainImage(trailer);
      });
  }

  private uploadUsedMainImage(trailer: UsedTrailer): void {
    const isExistImage = this.formDataMainImage.get('image');
    if (isExistImage) {
      this.usedTrailersService
        .editMainUsedTrailerImage(trailer.id, this.formDataMainImage)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.isCreationMode) {
            this.usedTrailerStateManagementService.sendUsedTrailerCreationEvent(trailer);
            this.dialogBelonging.eventsController.close(false);
          } else {
            this.dialogBelonging.eventsController.close(true);
          }
        });
    } else {
      if (this.isCreationMode) {
        this.usedTrailerStateManagementService.sendUsedTrailerCreationEvent(trailer);
        this.dialogBelonging.eventsController.close(false);
      } else {
        this.dialogBelonging.eventsController.close(true);
      }
    }
  }

  private uploadImagesForTrailer(trailer: TrailerFull): void {
    this.trailersService
      .addImagesToTrailer(trailer.id, this.formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.uploadMainImage(trailer);
      });
  }

  private uploadMainImage(trailer: TrailerFull): void {
    const isExistImage = this.formDataMainImage.get('image');
    if (isExistImage) {
      this.trailersService
        .editMainTrailerImage(trailer.id, this.formDataMainImage)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.isCreationMode) {
            this.trailerStateManagementService.sendTrailerCreationEvent(trailer);
            this.dialogBelonging.eventsController.close(false);
          } else {
            this.dialogBelonging.eventsController.close(true);
          }
        });
    } else {
      if (this.isCreationMode) {
        this.trailerStateManagementService.sendTrailerCreationEvent(trailer);
        this.dialogBelonging.eventsController.close(false);
      } else {
        this.dialogBelonging.eventsController.close(true);
      }
    }
  }

  private removeOfficeImages(trailer: OfficeTrailer, isUploadImage: boolean): void {
    this.removableImages.forEach((imageId) => {
      this.officeTrailersService
        .deleteOfficeTrailerImage(trailer.id, imageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!isUploadImage) {
            this.dialogBelonging.eventsController.close(true);
          }
        });
    });
  }

  private removeUsedImages(trailer: UsedTrailer, isUploadImage: boolean): void {
    this.removableImages.forEach((imageId) => {
      this.usedTrailersService
        .deleteUsedTrailerImage(trailer.id, imageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!isUploadImage) {
            this.dialogBelonging.eventsController.close(true);
          }
        });
    });
  }

  private removeImages(trailer: TrailerFull, isUploadImage: boolean): void {
    this.removableImages.forEach((imageId) => {
      this.trailersService
        .deleteTrailerImage(trailer.id, imageId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!isUploadImage) {
            this.dialogBelonging.eventsController.close(true);
          }
        });
    });
  }

  private prepareToShowTrailerTypes(): void {
    if (this.dialogBelonging.customData?.isOfficeTrailer) {
      this.officeTrailersService
        .getGroupedOfficeDimensionProperties()
        .pipe(takeUntil(this.destroy$))
        .subscribe((types) => {
          this.groupedOfficeTypes = types;
          this.officeFriendlyTypes = types.map((type) => type.typeName);
          const trailerTypeIndex = this.trailerProperties.findIndex(
            (item) => item.property === 'trailerType'
          );
          this.trailerProperties[trailerTypeIndex].dropdown = this.officeFriendlyTypes;
          this.handleOfficeTrailerType(this.trailerType?.value);
        });
    }

    if (!this.dialogBelonging.customData?.isOfficeTrailer) {
      this.trailersService
        .getTrailerTypes()
        .pipe(takeUntil(this.destroy$))
        .subscribe((types) => {
          this.types = types;
          this.handleTrailerType(this.trailerType?.value);
        });
    }
  }

  private handleTrailerSize(value: string): void {
    const currentTrailerType = this.types.find(
      (type) => type.trailerType === this.trailerType?.value
    );
    if (currentTrailerType) {
      const activeCapacity = currentTrailerType.trailers.find(
        (trailer) => `${trailer.sizeName} (${trailer.loadingCapacity}lbs)` === value
      );
      this.loadingCapacity?.setValue(activeCapacity?.loadingCapacity);
      this.loadingCapacity?.disable();
    }
  }

  private handleOfficeTrailerType(value: string): void {
    const currentTrailerType = this.groupedOfficeTypes.find((type) => type.typeName === value);
    if (currentTrailerType) {
      const trailerSizeIndex = this.trailerProperties.findIndex((item) => item.property === 'size');
      this.trailerProperties[trailerSizeIndex].dropdown = currentTrailerType.dimensions.map(
        (item) => item.sizeName
      );
    }
  }

  private handleTrailerType(value: string): void {
    const currentTrailerType = this.types.find((type) => type.trailerType === value);
    if (currentTrailerType) {
      const trailerSizeIndex = this.trailerProperties.findIndex((item) => item.property === 'size');
      const trailerCapacity = this.trailerProperties.findIndex(
        (item) => item.property === 'loadingCapacity'
      );
      this.trailerProperties[trailerSizeIndex].dropdown = currentTrailerType.trailers.map(
        (item) => `${item.sizeName} (${item.loadingCapacity}lbs)`
      );
      this.trailerProperties[trailerCapacity].dropdown = currentTrailerType.trailers.map(
        (item) => item.loadingCapacity
      );
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      numId: [
        null,
        this.dialogBelonging.customData?.isUsedTrailer ||
        this.dialogBelonging.customData?.isOfficeTrailer
          ? []
          : Validators.required,
      ],
      comments: [null],
      trailerType: [
        null,
        this.dialogBelonging.customData?.isUsedTrailer ||
        this.dialogBelonging.customData?.isOfficeTrailer
          ? []
          : Validators.required,
      ],
      size: [null, Validators.required],
      loadingCapacity: [
        null,
        this.dialogBelonging.customData?.isUsedTrailer ||
        this.dialogBelonging.customData?.isOfficeTrailer
          ? []
          : Validators.required,
      ],
      manufacturer: [null],
      year: [null],
      vin: [null],
      plateNumber: [null],
      isRegistered: [null],
      dayOfSale: [null],
      salePrice: [null],
      dateOfPurchase: [null],
      priceOfPurchasing: [null],
      inspectionExpire: [null],
      status: [
        null,
        this.dialogBelonging.customData?.isUsedTrailer ||
        this.dialogBelonging.customData?.isOfficeTrailer
          ? []
          : Validators.required,
      ],
      unitNumber: [null, this.dialogBelonging.customData?.isUsedTrailer ? [] : Validators.required],
      name: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      type: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      price: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      buildingSize: [
        null,
        this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : [],
      ],
      hitch: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      weight: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      power: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      occupancy: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      heat: [null, this.dialogBelonging.customData?.isUsedTrailer ? Validators.required : []],
      heaterType: [null],
      airConditioning: [
        null,
        this.dialogBelonging.customData?.isOfficeTrailer ? Validators.required : [],
      ],
      anyOfficeSpaces: [null],
      sellingPrice: [
        null,
        this.dialogBelonging.customData?.isOfficeTrailer ? Validators.required : [],
      ],
    });
  }

  private setTitle(): void {
    this.title = this.dialogBelonging.customData.title;
    this.trailer = this.dialogBelonging.customData.trailer;
    this.usedTrailer = this.dialogBelonging.customData.trailer;
    this.officeTrailer = this.dialogBelonging.customData.trailer;
    if (this.officeTrailer) {
      this.officeTrailer.sizeName = this.officeTrailer?.dimensionsData?.sizeName;
      this.officeTrailer.size = this.officeTrailer?.dimensionsData?.size;
      this.officeTrailer.trailerType = this.officeTrailer?.dimensionsData?.typeName;
    }
    this.updateImage(
      this.dialogBelonging.customData?.isUsedTrailer
        ? this.usedTrailer
        : this.dialogBelonging.customData?.isOfficeTrailer
        ? this.officeTrailer
        : this.trailer
    );
    this.isEditMode = this.dialogBelonging.customData.title !== 'Details';
    this.isCreationMode = !this.dialogBelonging.customData.trailer;
    this.isFiles = !!this.dialogBelonging.customData?.trailer?.images;
    if (this.isCreationMode) {
      this.handleEmptyTrailer();
    } else {
      this.dimensions = this.dialogBelonging.customData.trailer.dimensions;
    }

    if (this.dialogBelonging.customData?.isUsedTrailer) {
      this.handleUsedTrailerProperties();
    }
    if (this.dialogBelonging.customData?.isOfficeTrailer) {
      this.handleOfficeTrailerProperties();
    }
    if (
      !this.dialogBelonging.customData?.isUsedTrailer &&
      !this.dialogBelonging.customData?.isOfficeTrailer
    ) {
      this.handleTrailerProperties();
    }
  }

  private handleEmptyTrailer(): void {
    if (this.dialogBelonging.customData?.isUsedTrailer) {
      this.usedTrailer = {
        id: '',
        name: '',
        description: '',
        size: '',
        type: '',
        price: 0,
        buildingSize: '',
        hitch: '',
        weight: 0,
        occupancy: '',
        power: '',
        heat: '',
        image: null,
        images: [],
        mainImage: null,
      } as UsedTrailer;
      this.updateImage(this.usedTrailer);
    }
    if (this.dialogBelonging.customData?.isOfficeTrailer) {
      this.officeTrailer = {
        id: '',
        numId: '',
        comments: '',
        size: '',
        trailerTypeName: '',
        trailerType: '',
        sizeName: '',
        loadingCapacity: '',
        manufacturer: '',
        year: new Date().getFullYear(),
        vin: '',
        plateNumber: '',
        isRegistered: false,
        dayOfSale: '',
        dimensionsData: {
          dimensions: '',
          friendlyName: '',
          size: '',
          sizeName: '',
          loadingCapacity: '',
          transportationMode: '',
          transportationModeName: '',
          typeName: '',
        },
        salePrice: 0,
        dateOfPurchase: '',
        priceOfPurchasing: 0,
        inspectionExpire: '',
        status: '',
        dimensions: '',
        isSold: false,
        mainImage: null,
        image: null,
        images: [],
        isPinned: false,
        unitNumber: '',
        sellingPrice: 0,
        heaterType: '',
        airConditioning: false,
        anyOfficeSpaces: '',
        isOfficeTrailer: true,
        transportationMode: '',
        contract: '',
        projectName: '',
      };
      this.updateImage(this.officeTrailer);
    }
    if (
      !this.dialogBelonging.customData?.isUsedTrailer &&
      !this.dialogBelonging.customData?.isOfficeTrailer
    ) {
      this.trailer = {
        id: '',
        numId: '',
        comments: '',
        size: '',
        sizeName: '',
        trailerTypeName: '',
        loadingCapacity: '',
        manufacturer: '',
        year: new Date().getFullYear(),
        vin: '',
        plateNumber: '',
        isRegistered: false,
        dayOfSale: '',
        salePrice: 0,
        dateOfPurchase: '',
        priceOfPurchasing: 0,
        inspectionExpire: '',
        status: '',
        name: '',
        description: '',
        dimensions: '',
        trailerType: '',
        power: '',
        hitch: '',
        isSold: false,
        orders: [],
        mainImage: null,
        image: null,
        images: [],
        isPinned: false,
        statusForPeriod: null,
        unitNumber: '',
      } as TrailerFull;
      this.updateImage(this.trailer);
    }
  }

  private handleOfficeTrailerProperties(): void {
    for (const name in this.officeTrailer) {
      if (Object.prototype.hasOwnProperty.call(this.officeTrailer, name)) {
        switch (name) {
          case 'numId':
            this.trailerProperties.push({
              id: 0,
              property: name,
              type: 'string',
              value: this.officeTrailer[name],
              title: 'ID',
              required: true,
            });
            this.numId?.setValue(this.officeTrailer[name]);
            break;
          case 'dayOfSale':
            this.trailerProperties.push({
              id: 1,
              property: name,
              type: 'date',
              value: this.officeTrailer[name],
              title: 'Day of sale',
            });
            this.dayOfSale?.setValue(
              this.officeTrailer[name] ? new Date(this.officeTrailer[name]) : ''
            );
            break;
          case 'trailerType':
            this.trailerProperties.push({
              id: 2,
              property: name,
              type: 'dropdown',
              value: this.officeTrailer[name],
              title: 'Type',
              dropdown: this.officeFriendlyTypes,
              required: true,
            });
            this.trailerType?.setValue(this.officeTrailer[name]);
            break;
          case 'size':
            this.trailerProperties.push({
              id: 4,
              property: name,
              type: 'dropdown',
              value: this.officeTrailer['sizeName'],
              title: 'Size',
              dropdown: [],
              required: true,
            });
            this.size?.setValue(this.officeTrailer['sizeName']);
            break;
          case 'manufacturer':
            this.trailerProperties.push({
              id: 10,
              property: name,
              type: 'string',
              value: this.officeTrailer[name],
              title: 'Manufacturer',
            });
            this.manufacturer?.setValue(this.officeTrailer[name]);
            break;
          case 'dateOfPurchase':
            this.trailerProperties.push({
              id: 12,
              property: name,
              type: 'date',
              value: this.officeTrailer[name],
              title: 'Day of Purchase',
            });
            this.dateOfPurchase?.setValue(
              this.officeTrailer[name] ? new Date(this.officeTrailer[name]) : new Date()
            );
            break;
          case 'priceOfPurchasing':
            this.trailerProperties.push({
              id: 14,
              property: name,
              type: 'number',
              value: this.officeTrailer[name],
              title: 'Price of purchasing',
            });
            this.priceOfPurchasing?.setValue(this.officeTrailer[name]);
            break;
          case 'salePrice':
            this.trailerProperties.push({
              id: 3,
              property: name,
              type: 'number',
              value: this.officeTrailer[name],
              title: 'Sale price',
            });
            this.salePrice?.setValue(this.officeTrailer[name]);
            break;
          case 'year':
            this.trailerProperties.push({
              id: 5,
              property: name,
              type: 'dropdown',
              value: this.officeTrailer[name],
              title: 'Year',
              dropdown: getYears(),
            });
            this.year?.setValue(this.officeTrailer[name]);
            break;
          case 'vin':
            this.trailerProperties.push({
              id: 7,
              property: name,
              type: 'string',
              value: this.officeTrailer[name],
              title: 'VIN #',
            });
            this.vin?.setValue(this.officeTrailer[name]);
            break;
          case 'plateNumber':
            this.trailerProperties.push({
              id: 9,
              property: name,
              type: 'string',
              value: this.officeTrailer[name],
              title: 'Plate #',
            });
            this.plateNumber?.setValue(this.officeTrailer[name]);
            break;
          case 'isRegistered':
            this.trailerProperties.push({
              id: 11,
              property: name,
              type: 'dropdown',
              value: this.officeTrailer[name],
              title: 'Registered yes/no',
              dropdown: ['Yes', 'No'],
            });
            this.isRegistered?.setValue(this.officeTrailer[name] ? 'Yes' : 'No');
            break;
          case 'inspectionExpire':
            this.trailerProperties.push({
              id: 13,
              property: name,
              type: 'date',
              value: this.officeTrailer[name],
              title: 'Inspection expire',
            });
            this.inspectionExpire?.setValue(
              this.officeTrailer[name] ? new Date(this.officeTrailer[name]) : new Date()
            );
            break;
          case 'comments':
            this.trailerProperties.push({
              id: 15,
              property: name,
              type: 'textarea',
              value: this.officeTrailer[name],
              title: 'Comments',
            });
            this.comments?.setValue(this.officeTrailer[name]);
            break;
          case 'unitNumber':
            this.trailerProperties.push({
              id: 17,
              property: name,
              type: 'string',
              value: this.officeTrailer[name],
              title: 'Unit Number',
              required: true,
            });
            this.unitNumber?.setValue(this.officeTrailer[name]);
            break;
          case 'sellingPrice':
            this.trailerProperties.push({
              id: 19,
              property: name,
              type: 'number',
              value: this.officeTrailer[name],
              title: 'Selling Price',
            });
            this.sellingPrice?.setValue(this.officeTrailer[name]);
            break;
          case 'heaterType':
            this.trailerProperties.push({
              id: 23,
              property: name,
              type: 'dropdown',
              value: this.officeTrailer[name],
              title: 'Heater Type',
              dropdown: ['ELECTRIC', 'PROPANE', 'BOTH'],
            });
            this.heaterType?.setValue(this.officeTrailer[name]);
            break;
          case 'airConditioning':
            this.trailerProperties.push({
              id: 25,
              property: name,
              type: 'dropdown',
              value: this.officeTrailer[name],
              title: 'Air Conditioning',
              dropdown: ['Yes', 'No'],
            });
            this.airConditioning?.setValue(this.officeTrailer[name] ? 'Yes' : 'No');
            break;
          case 'anyOfficeSpaces':
            this.trailerProperties.push({
              id: 27,
              property: name,
              type: 'string',
              value: this.officeTrailer[name],
              title: 'Office Spaces',
            });
            this.anyOfficeSpaces?.setValue(this.officeTrailer[name]);
            break;
          default:
            break;
        }
      }
    }
    this.trailerProperties = this.trailerProperties.sort((a, b) => a.id - b.id);
  }

  private handleUsedTrailerProperties(): void {
    for (const name in this.usedTrailer) {
      if (Object.prototype.hasOwnProperty.call(this.usedTrailer, name)) {
        switch (name) {
          case 'name':
            this.trailerProperties.push({
              id: 0,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Name',
            });
            this.name?.setValue(this.usedTrailer[name]);
            break;
          case 'size':
            this.trailerProperties.push({
              id: 4,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Size',
              dropdown: [],
            });
            this.size?.setValue(this.usedTrailer[name]);
            break;
          case 'type':
            this.trailerProperties.push({
              id: 1,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Type',
            });
            this.type?.setValue(this.usedTrailer[name]);
            break;
          case 'price':
            this.trailerProperties.push({
              id: 6,
              property: name,
              type: 'number',
              value: this.usedTrailer[name],
              title: 'Price',
            });
            this.price?.setValue(this.usedTrailer[name]);
            break;
          case 'buildingSize':
            this.trailerProperties.push({
              id: 8,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Building Size',
            });
            this.buildingSize?.setValue(this.usedTrailer[name]);
            break;
          case 'hitch':
            this.trailerProperties.push({
              id: 12,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Hitch',
            });
            this.hitch?.setValue(this.usedTrailer[name]);
            break;
          case 'weight':
            this.trailerProperties.push({
              id: 14,
              property: name,
              type: 'number',
              value: this.usedTrailer[name],
              title: 'Weight',
            });
            this.weight?.setValue(this.usedTrailer[name]);
            break;
          case 'occupancy':
            this.trailerProperties.push({
              id: 3,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Occupancy',
            });
            this.occupancy?.setValue(this.usedTrailer[name]);
            break;
          case 'power':
            this.trailerProperties.push({
              id: 5,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Power',
            });
            this.power?.setValue(this.usedTrailer[name]);
            break;
          case 'heat':
            this.trailerProperties.push({
              id: 7,
              property: name,
              type: 'string',
              value: this.usedTrailer[name],
              title: 'Heat',
            });
            this.heat?.setValue(this.usedTrailer[name]);
            break;
          case 'description':
            this.trailerProperties.push({
              id: 15,
              property: 'comments',
              type: 'textarea',
              value: this.usedTrailer[name],
              title: 'Comments',
            });
            this.comments?.setValue(this.usedTrailer[name]);
            break;
          default:
            break;
        }
      }
    }
    this.trailerProperties = this.trailerProperties.sort((a, b) => a.id - b.id);
  }

  private handleTrailerProperties(): void {
    for (const name in this.trailer) {
      if (Object.prototype.hasOwnProperty.call(this.trailer, name)) {
        switch (name) {
          case 'numId':
            this.trailerProperties.push({
              id: 0,
              property: name,
              type: 'string',
              value: this.trailer[name],
              title: 'ID',
              required: true,
            });
            this.numId?.setValue(this.trailer[name]);
            break;
          case 'trailerType':
            this.trailerProperties.push({
              id: 2,
              property: name,
              type: 'dropdown',
              value: this.trailer[name],
              title: 'Type',
              dropdown: this.trailerTypes,
              required: true,
            });
            this.trailerType?.setValue(this.trailer[name]);
            break;
          case 'size':
            this.trailerProperties.push({
              id: 4,
              property: name,
              type: 'dropdown',
              value: this.trailer['sizeName'],
              title: 'Size',
              dropdown: [],
              required: true,
            });
            this.size?.setValue(`${this.trailer['sizeName']} (${this.trailer.loadingCapacity}lbs)`);
            break;
          case 'dayOfSale':
            this.trailerProperties.push({
              id: 1,
              property: name,
              type: 'date',
              value: this.trailer[name],
              title: 'Day of sale',
            });
            this.dayOfSale?.setValue(this.trailer[name] ? new Date(this.trailer[name]) : '');
            break;
          case 'loadingCapacity':
            this.trailerProperties.push({
              id: 6,
              property: name,
              type: 'dropdown',
              value: this.trailer[name],
              title: 'Loading capacity',
              dropdown: [],
            });
            this.loadingCapacity?.setValue(this.trailer[name]);
            this.loadingCapacity?.disable();
            break;
          case 'status':
            this.trailerProperties.push({
              id: 8,
              property: name,
              type: 'dropdown',
              value: this.trailer[name],
              title: 'Status',
              dropdown: this.statuses,
              required: true,
            });
            this.status?.setValue(this.trailer[name]);
            break;
          case 'manufacturer':
            this.trailerProperties.push({
              id: 10,
              property: name,
              type: 'string',
              value: this.trailer[name],
              title: 'Manufacturer',
            });
            this.manufacturer?.setValue(this.trailer[name]);
            break;
          case 'dateOfPurchase':
            this.trailerProperties.push({
              id: 12,
              property: name,
              type: 'date',
              value: this.trailer[name],
              title: 'Day of Purchase',
            });
            this.dateOfPurchase?.setValue(
              this.trailer[name] ? new Date(this.trailer[name]) : new Date()
            );
            break;
          case 'priceOfPurchasing':
            this.trailerProperties.push({
              id: 14,
              property: name,
              type: 'number',
              value: this.trailer[name],
              title: 'Price of purchasing',
            });
            this.priceOfPurchasing?.setValue(this.trailer[name]);
            break;
          case 'salePrice':
            this.trailerProperties.push({
              id: 3,
              property: name,
              type: 'number',
              value: this.trailer[name],
              title: 'Sale price',
            });
            this.salePrice?.setValue(this.trailer[name]);
            break;
          case 'year':
            this.trailerProperties.push({
              id: 5,
              property: name,
              type: 'dropdown',
              value: this.trailer[name],
              title: 'Year',
              dropdown: getYears(),
            });
            this.year?.setValue(this.trailer[name]);
            break;
          case 'vin':
            this.trailerProperties.push({
              id: 7,
              property: name,
              type: 'string',
              value: this.trailer[name],
              title: 'VIN #',
            });
            this.vin?.setValue(this.trailer[name]);
            break;
          case 'plateNumber':
            this.trailerProperties.push({
              id: 9,
              property: name,
              type: 'string',
              value: this.trailer[name],
              title: 'Plate #',
            });
            this.plateNumber?.setValue(this.trailer[name]);
            break;
          case 'isRegistered':
            this.trailerProperties.push({
              id: 11,
              property: name,
              type: 'dropdown',
              value: this.trailer[name],
              title: 'Registered yes/no',
              dropdown: ['Yes', 'No'],
            });
            this.isRegistered?.setValue(this.trailer[name] ? 'Yes' : 'No');
            break;
          case 'inspectionExpire':
            this.trailerProperties.push({
              id: 13,
              property: name,
              type: 'date',
              value: this.trailer[name],
              title: 'Inspection expire',
            });
            this.inspectionExpire?.setValue(
              this.trailer[name] ? new Date(this.trailer[name]) : new Date()
            );
            break;
          case 'comments':
            this.trailerProperties.push({
              id: 15,
              property: name,
              type: 'textarea',
              value: this.trailer[name],
              title: 'Comments',
            });
            this.comments?.setValue(this.trailer[name]);
            break;
          case 'unitNumber':
            this.trailerProperties.push({
              id: 16,
              property: name,
              type: 'string',
              value: this.trailer[name],
              title: 'Unit Number',
              required: true,
            });
            this.unitNumber?.setValue(this.trailer[name]);
            break;
          default:
            break;
        }
      }
    }
    this.trailerProperties = this.trailerProperties.sort((a, b) => a.id - b.id);
  }
}
