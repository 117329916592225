import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { DialogBelonging } from '@costlydeveloper/ngx-awesome-popup';
import { Subject, takeUntil } from 'rxjs';
import { OrdersApiService } from 'src/app/core/services/api/orders-api.service';
import { TimeZoneHelperService } from 'src/app/core/services/timezone.helper';
import { getFormattedDateTimeForServer } from 'src/app/core/utils/formatted-date';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
})
export class SendEmailDialogComponent implements OnInit {
  form!: FormGroup;

  private readonly destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    @Inject('dialogBelonging') public dialogBelonging: DialogBelonging,
    private ordersService: OrdersApiService,
    private timeZoneHelperService: TimeZoneHelperService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  closePopup(showMessage: boolean): void {
    this.dialogBelonging.eventsController.close(showMessage);
  }

  submit(): void {
    const startRentDate = getFormattedDateTimeForServer(this.dialogBelonging.customData.startDate);
    const endRentDate = getFormattedDateTimeForServer(this.dialogBelonging.customData.endDate);
    const dimensions = this.dialogBelonging.customData.dimensions;
    const timezone = this.timeZoneHelperService.getTimeZone();

    this.ordersService
      .chooseOrderTrailer(startRentDate, endRentDate, dimensions, timezone)
      .pipe(takeUntil(this.destroy$))
      .subscribe((order) => {
        this.ordersService
          .sendReservationToEmail(order.id, this.email?.value)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.closePopup(true);
          });
      });
  }

  get email(): AbstractControl | null {
    return this.form?.get('email');
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private initForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
