<div class="trailer-upload-image">
  <div
    class="trailer-upload-image__main-image-container"
    (click)="openImageDetails()"
    [class.trailer-upload-image__main-image-container--image]="mainImage"
    [ngStyle]="{
      'background-image': mainImage ? 'url' + '(' + mainImage.contentPath + ')' : 'none'
    }"
    *ngIf="!isCreationMode"
  >
    <div
      class="trailer-upload-image__image-length"
      *ngIf="images && images.length > 1 && !openDetails"
    >
      +{{ images.length - 1 }}
      <img src="/assets/images/image-cards.svg" alt="Image Cards" />
    </div>
    <input
      type="file"
      [ngStyle]="{ display: !isEditMode ? 'block' : 'none' }"
      #fileMainImageDropRef
      class="trailer-upload-image__image-file-input"
      id="fileMainImageDropRef"
      accept="image/*"
      (change)="mainFileBrowserHandler($event)"
    />
  </div>
  <div *ngIf="openDetails" class="trailer-upload-image__images">
    <div class="trailer-upload-image__image-download" *ngIf="!isEditMode">
      <img
        src="/assets/images/file-upload.svg"
        class="trailer-upload-image__image-download-icon"
        alt="File Upload"
      />
      <input
        type="file"
        [ngStyle]="{ display: !isEditMode ? 'block' : 'none' }"
        #fileImageDropRef
        class="trailer-upload-image__image-file-input"
        id="fileImageDropRef"
        multiple
        accept="image/*"
        (change)="fileBrowseHandler($event)"
      />
    </div>
    <div
      class="trailer-upload-image__image"
      *ngFor="let preview of previews; let i = index"
      [ngStyle]="{
        'background-image': preview ? 'url' + '(' + preview + ')' : 'none'
      }"
    >
      <div class="trailer-upload-image__remove-image" (click)="removeImage(i)" *ngIf="!isEditMode">
        <i class="icon-close trailer-upload-image__icon"></i>
      </div>
    </div>
    <div
      class="trailer-upload-image__image"
      *ngFor="let image of images"
      [ngStyle]="{
        'background-image': mainImage ? 'url' + '(' + image.contentPath + ')' : 'none'
      }"
    >
      <div
        class="trailer-upload-image__remove-image"
        (click)="removeImageFromList(image)"
        *ngIf="!isEditMode"
      >
        <i class="icon-close trailer-upload-image__icon"></i>
      </div>
    </div>
  </div>
  <div>
    <div
      *ngIf="isCreationMode"
      class="trailer-upload-image__container"
      appDnd
      (fileDropped)="onFileDropped($event)"
    >
      <div class="trailer-upload-image__file-previews">
        <div
          class="trailer-upload-image__file-preview"
          *ngFor="let preview of previews; let i = index"
          [ngStyle]="{ 'background-image': 'url' + '(' + preview + ')' }"
        >
          <div class="trailer-upload-image__remove-image" (click)="removeImage(i)">
            <i class="icon-close trailer-upload-image__icon"></i>
          </div>
        </div>
      </div>
      <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        accept="image/*"
        (change)="fileBrowseHandler($event)"
      />
      <div class="trailer-upload-image__information">
        <img src="/assets/images/file-upload.svg" alt="File Upload" />
        <div class="trailer-upload-image__text">
          Please download or drop <br />
          trailer photo
        </div>
      </div>
    </div>
  </div>
  <button
    class="button button--main trailer-upload-image__button"
    (click)="closeImageDetails()"
    *ngIf="openDetails"
  >
    BACK
  </button>
</div>
