<div class="calendar-trailers-dialog">
  <div class="calendar-trailers-dialog__header">
    <div class="calendar-trailers-dialog__title">{{ title }}</div>
    <div class="calendar-trailers-dialog__action-icons">
      <i class="icon-close calendar-trailers-dialog__icon" (click)="closePopup()"></i>
    </div>
  </div>
  <div class="calendar-trailers-dialog__content">
    <app-calendar [isContract]="true" (selectedTrailer)="onSelectedTrailer($event)"></app-calendar>
  </div>
  <div class="calendar-trailers-dialog__actions">
    <button
      class="button button--cancel calendar-trailers-dialog__button"
      (click)="closePopup()"
    >
      CLOSE
    </button>
    <button
      class="button button--main calendar-trailers-dialog__button"
      (click)="onSelectedTrailerClose()"
      [disabled]="!selectedTrailer"
    >
      Select Trailer
    </button>
  </div>
</div>
