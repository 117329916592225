import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthApiService } from '../services/api/auth-api.service';
import { AuthStorageService } from '../services/auth-storage.service';
import { Auth } from '../models/login.model';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<Auth | null> = new BehaviorSubject<Auth | null>(
    null
  );

  constructor(
    public authStorageService: AuthStorageService,
    private authApiService: AuthApiService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string | null = this.authStorageService.getToken();

    if (token) {
      request = this.addToken(request, this.authStorageService.getToken());
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          return this.handle403Error(request, next);
        }
        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authApiService.refreshToken().pipe(
        switchMap((token) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          this.authStorageService.setRefreshToken(
            token.refreshToken,
            this.authStorageService.getAutoLogin() as any
          );
          return next.handle(this.addToken(request, token.accessToken));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          const refreshToken: any = jwt?.refreshToken;
          const accessToken: any = jwt?.accessToken;
          this.authStorageService.setRefreshToken(
            refreshToken,
            this.authStorageService.getAutoLogin() as any
          );
          return next.handle(this.addToken(request, accessToken));
        })
      );
    }
  }
}
