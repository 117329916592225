import { Component, Inject, OnInit } from '@angular/core';
import { DialogBelonging } from '@costlydeveloper/ngx-awesome-popup';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientListApiService } from '../../core/services/api/client-list-api.service';
import { ClientOffice } from '../../core/models/client.model';
import { Subject, takeUntil } from 'rxjs';
import { ClientsStateManagementService } from '../../core/services/api/clients-state-management.service';

@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss']
})
export class ClientDialogComponent implements OnInit {
  form!: FormGroup;
  title!: string;
  totalItems!: number;

  private clientId!: string;
  private readonly destroy$ = new Subject();

  constructor(
    @Inject('dialogBelonging') public dialogBelonging: DialogBelonging,
    private fb: FormBuilder,
    private clientListService: ClientListApiService,
    private clientsStateManagementService: ClientsStateManagementService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initTitle();
    this.getTotalCount();
    this.getNextNumId();
  }

  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  get numId(): AbstractControl | null {
    return this.form.get('numId');
  }

  get companyName(): AbstractControl | null {
    return this.form.get('companyName');
  }

  get phoneNumber(): AbstractControl | null {
    return this.form.get('phoneNumber');
  }

  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  get apEmail(): AbstractControl | null {
    return this.form.get('apEmail');
  }

  get address(): AbstractControl | null {
    return this.form.get('address');
  }

  get note(): AbstractControl | null {
    return this.form.get('note');
  }

  closePopup(isConfirm: boolean): void {
    this.dialogBelonging.eventsController.close(isConfirm);
  }

  getNextNumId() {
    this.clientListService
      .getNextNumId()
      .subscribe(({numId}) => {
        this.form.patchValue({
          numId:  numId,
        });
      })
  }

  save() {
    const client: ClientOffice = {
      numId: this.numId?.value,
      name: this.name?.value,
      companyName: this.companyName?.value,
      phoneNumber: this.phoneNumber?.value,
      email: this.email?.value,
      apEmail: this.apEmail?.value,
      address: this.address?.value,
      note: this.note?.value,
    }

    this.isEditMode() ? this.editClient(client) : this.createClient(client);
  }

  createClient(client: ClientOffice) {
    this.clientListService
      .createOfficeClient(client)
      .pipe(takeUntil(this.destroy$))
      .subscribe((client) => {
        this.clientsStateManagementService.sendClientCreationEvent(client)
        this.closePopup(true);
      })
  }

  editClient(client: ClientOffice) {
    this.clientListService
      .editOfficeClient(this.clientId, client)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp) => {
        this.dialogBelonging.eventsController.close(resp);
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      numId: ['', Validators.required],
      name: ['', Validators.required],
      companyName: ['', Validators.required],
      phoneNumber: [''],
      email: ['', Validators.required],
      apEmail: ['', Validators.required],
      address: [''],
      note: [''],
    })
  }

  private initTitle() {
    this.title = this.dialogBelonging.customData.title;
    this.clientId = this.dialogBelonging.customData?.client?.id;
    if (this.isEditMode()) {
      this.form.patchValue({
        numId: this.dialogBelonging.customData.client.numId,
        name: this.dialogBelonging.customData.client.name,
        companyName: this.dialogBelonging.customData.client.companyName,
        phoneNumber: this.dialogBelonging.customData.client.phoneNumber,
        email: this.dialogBelonging.customData.client.email,
        apEmail: this.dialogBelonging.customData.client.apEmail,
        address: this.dialogBelonging.customData.client.address,
        note: this.dialogBelonging.customData.client.note,
      })
    }
  }

  private isEditMode(): boolean {
    return !!this.dialogBelonging.customData.client;
  }

  private getTotalCount() {
    this.clientListService
      .getOfficeClients(1, 0, '', undefined)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.totalItems = response.count;
      });
  }

}
