import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  GroupedOfficeTrailerDimension,
  OfficeDimensions,
  OfficeTrailer,
  OfficeTrailerData,
  OfficeTrailerPost,
} from '../../models/office-trailer.model';
import { OfficeTrailerDimension } from '../../models/office-trailer-dimension.model';

@Injectable({
  providedIn: 'root',
})
export class OfficeTrailersApiService {
  private endpoint = environment.api;

  constructor(private http: HttpClient) {}

  getOfficeTrailers(
    limit: number,
    offset: number,
    sortBy: string | undefined,
    sortOrder: 'ASCENDING' | 'DESCENDING' | undefined
  ): Observable<OfficeTrailerData> {
    const url: string = `${this.endpoint}admin/trailers/office/search?limit=${limit}&offset=${offset}`;

    let params = new HttpParams();

    if (sortBy) {
      params = params.append('sortBy', sortBy);
    }

    if (sortOrder) {
      params = params.append('sortOrder', sortOrder);
    }

    return this.http.post<OfficeTrailerData>(url, null, { params }).pipe(
      map((data) => {
        data.data = data.data.map((item) => {
          item.isOfficeTrailer = true;
          return item;
        });
        return data;
      })
    );
  }

  getOfficeTrailersByDimensions(trailerDimensions: string[]): Observable<OfficeTrailerDimension[]> {
    const url: string = `${this.endpoint}admin/trailers/office/search/by-dimensions`;

    return this.http.post<OfficeTrailerDimension[]>(url, { trailerDimensions });
  }

  getOfficeDimensionProperties(): Observable<OfficeDimensions[]> {
    const url: string = `${this.endpoint}admin/trailers/office/dimensions/properties`;

    return this.http.get<OfficeDimensions[]>(url);
  }

  getGroupedOfficeDimensionProperties(): Observable<GroupedOfficeTrailerDimension[]> {
    const url: string = `${this.endpoint}admin/trailers/office/dimensions/properties/grouped`;

    return this.http.get<GroupedOfficeTrailerDimension[]>(url);
  }

  createOfficeTrailer(trailer: OfficeTrailerPost): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office`;

    return this.http.post<OfficeTrailer>(url, trailer);
  }

  editOfficeTrailer(trailerId: string, trailer: OfficeTrailerPost): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}`;

    return this.http.put<OfficeTrailer>(url, trailer);
  }

  editMainOfficeTrailerImage(trailerId: string, formData: FormData): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/images/main`;

    return this.http.patch<OfficeTrailer>(url, formData);
  }

  addImagesToOfficeTrailer(trailerId: string, formData: FormData): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/images`;

    return this.http.post<OfficeTrailer>(url, formData);
  }

  deleteOfficeTrailerImage(trailerId: string, imageId: string): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/images/${imageId}`;

    return this.http.delete<OfficeTrailer>(url);
  }

  pinTrailer(trailerId: string, pin: boolean): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/pin?pin=${pin}`;

    return this.http.patch<OfficeTrailer>(url, null);
  }

  setStatusOnHold(trailerId: string): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/status/hold`;

    return this.http.patch<OfficeTrailer>(url, null);
  }

  setStatusSold(trailerId: string): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/status/sold`;

    return this.http.patch<OfficeTrailer>(url, null);
  }

  setStatusAvailable(trailerId: string): Observable<OfficeTrailer> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}/status/available`;

    return this.http.patch<OfficeTrailer>(url, null);
  }

  deleteTrailer(trailerId: string): Observable<null> {
    const url: string = `${this.endpoint}admin/trailers/office/${trailerId}`;

    return this.http.delete<null>(url);
  }
}
