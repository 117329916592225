<div class="reservation-details-dialog">
  <div class="reservation-details-dialog__header">
    <div class="reservation-details-dialog__title">Reservation Details</div>
    <div class="reservation-details-dialog__action-icons">
      <i class="icon-close reservation-details-dialog__icon" (click)="closePopup()"></i>
    </div>
  </div>
  <div class="reservation-details-dialog__content">
    <div
      class="reservation-details-dialog__image"
      [ngStyle]="{ 'background-image': 'url' + '(' + trailer?.mainImage?.contentPath + ')' }"
    ></div>
    <p class="reservation-details-dialog__trailer-title">
      {{ trailer?.sizeName }} ({{ trailer?.loadingCapacity | number }}lb)
      {{ trailer?.trailerTypeName }} TRAILER
    </p>
    <div class="reservation-details-dialog__trailer-information">
      <div class="reservation-details-dialog__estimated-dates">
        <div class="reservation-details-dialog__pick-up"
             [popper]="popperAction"
             [popperPlacement]="'bottom'"
             [popperHideOnClickOutside]="true"
             [popperDisableAnimation]="true"
             [popperTrigger]="'click'"
             [popperApplyArrowClass]="'shared-table__arrow'"
             [popperPositionFixed]="true">
          <p class="reservation-details-dialog__trailer-text">Pick up</p>
          <p class="reservation-details-dialog__date popper-action"
          >{{ startDate | date: 'MMMM d, YYYY hh:mm a' }}</p>
        </div>
        <div class="reservation-details-dialog__arrow-divider">
          <i class="icon-angle-right reservation-details-dialog__icon"></i>
        </div>
        <div class="reservation-details-dialog__return-date">
          <p class="reservation-details-dialog__trailer-text">Return</p>
          <p class="reservation-details-dialog__date">{{ endDate | date: 'MMMM d, y hh:mm a' }}</p>
        </div>
      </div>
      <popper-content #popperAction>
        <div class="popper form">
         <div *ngFor="let hour of timeRange"
              (click)="setStartDateTime(hour)"
              class="popper-item"
              [class.active]="isActiveTime(hour)"
         >
           {{formatHours(hour)}}
         </div>
        </div>
      </popper-content>
    </div>
    <div class="reservation-details-dialog__actions">
      <button
        class="button button--cancel reservation-details-dialog__button reservation-details-dialog__button--email"
        (click)="openDialogToSendLinkToEmail()"
      >
        <i class="icon-mail reservation-details-dialog__icon"></i>
        Send link to email
      </button>
      <button
        class="button button--main reservation-details-dialog__button"
        (click)="navigateToBookingPage()"
      >
        CONTINUE BOOKING
        <i class="icon-arrow-small-right reservation-details-dialog__icon"></i>
      </button>
    </div>
  </div>
</div>
