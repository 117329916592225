import { Component, Inject, OnInit } from '@angular/core';
import { DialogBelonging } from '@costlydeveloper/ngx-awesome-popup';

@Component({
  selector: 'app-calendar-trailers-dialog',
  templateUrl: './calendar-trailers-dialog.component.html',
  styleUrls: ['./calendar-trailers-dialog.component.scss']
})
export class CalendarTrailersDialogComponent implements OnInit {
  title!: string;

  selectedTrailer: any;

  constructor(
    @Inject('dialogBelonging') public dialogBelonging: DialogBelonging,
  ) { }

  ngOnInit(): void {
    this.initTitle();
  }

  closePopup(): void {
    this.dialogBelonging.eventsController.close(null);
  }

  onSelectedTrailer(trailer: any) {
    this.selectedTrailer = trailer;
  }

  onSelectedTrailerClose() {
    this.dialogBelonging.eventsController.close(this.selectedTrailer);
  }

  private initTitle(): void {
    this.title = this.dialogBelonging.customData.title;
  }

}
