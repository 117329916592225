export const getYears = (min = 1980): number[] => {
  const max = new Date().getFullYear();
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }

  return years;
};
