import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { SortItemsHelperService } from 'src/app/core/services/sort-items.helper';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() title?: string;
  @Input() headers!: string[];
  @Input() class!: string;
  @Input() sortableItems!: string[];
  @Input() acceptableActions!: string[];
  @Input() sortItem!: string;
  @Input() module!: string;
  @Input() cityFilter!: string;
  @Input() priceListFilter!: string;
  @Input() addItem!: (args?: any) => void;
  @Output() viewResultsOfSort = new EventEmitter<{
    sortItem: string;
    sortOrder: 'DESCENDING' | 'ASCENDING' | undefined;
  }>();
  @Output() emitExport = new EventEmitter<boolean>();
  @Output() emitImport = new EventEmitter<boolean>();

  sortOrder: 'DESCENDING' | 'ASCENDING' | undefined = undefined;
  sortableItem!: string;
  activeSortPopover = false;
  activeFilterPopover = false;

  widths: number[] = [];

  constructor(
    private sortItemsHelperService: SortItemsHelperService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.setWidths();
  }

  onExport(): void {
    this.emitExport.emit(true);
  }

  onImport(): void {
    this.emitImport.emit(true);
  }

  onResizeStart(event: MouseEvent, key: string) {
    const table = this.el.nativeElement.querySelector('table');
    const th = table.querySelector(`th:nth-child(${this.getColumnIndex(key)})`);

    const startX = event.pageX;
    const startWidth = th.offsetWidth;

    const onMouseMove = (event: MouseEvent) => {
      const width = startWidth + (event.pageX - startX);
      this.renderer.setStyle(th, 'width', `${width}px`);
    };

    const onMouseUp = () => {
      this.setWidths();
      this.renderer.removeChild(document.body, ghostTh);
      this.renderer.removeClass(document.body, 'resize-in-progress');
      this.renderer.removeClass(th, 'resizing');
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const ghostTh = th.cloneNode(true);
    this.renderer.setStyle(ghostTh, 'visibility', 'hidden');
    this.renderer.setStyle(ghostTh, 'position', 'absolute');
    this.renderer.setStyle(ghostTh, 'width', `${startWidth}px`);
    this.renderer.appendChild(document.body, ghostTh);

    this.renderer.addClass(document.body, 'resize-in-progress');
    this.renderer.addClass(th, 'resizing');

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  getColumnIndex(key: string): number {
    const headers = Array.from(this.el.nativeElement.querySelectorAll('th'));
    const index = headers.findIndex((th: any) => th.textContent.trim() === key);
    return index + 1;
  }

  setWidths() {
    const table = this.el.nativeElement.querySelector('table');
    const headers = table.querySelectorAll('th');
    this.widths = Array.from(headers).map((th: any) => th.offsetWidth);
    headers.forEach((th: any, i: number) => {
      this.renderer.setStyle(th, 'width', `${this.widths[i]}px`);
    });
  }

  selectSortItem(event: Event, item: string): void {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.sortableItem = item;
      switch (this.module) {
        case 'CLIENT':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getClientSort(item));
          break;
        case 'MAIN_DASHBOARD':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getTrailerStatisticsSort(item));
          break;
        case 'INVENTORY':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getTrailerInventorySort(item));
          break;
        case 'OFFICE_TRAILERS':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getOfficeTrailersSort(item));
          break;
        case 'ORDER_HISTORY':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getOrderHistory(item));
          break;
        case 'CITY_DELIVERIES':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getCityDeliveries(item));
          break;
        case 'SALE':
          ({ sortItem: this.sortItem, sortOrder: this.sortOrder } =
            this.sortItemsHelperService.getSale(item));
          break;
        default:
          break;
      }
    } else {
      this.sortItem = '';
      this.sortOrder = undefined;
      this.sortableItem = '';
    }
  }

  onShownSortPopover(): void {
    this.activeSortPopover = true;
  }

  onHideSortPopover(): void {
    this.activeSortPopover = false;
  }

  onShownFilterPopover(): void {
    this.activeFilterPopover = true;
  }

  onHideFilterPopover(): void {
    this.activeFilterPopover = false;
  }

  clearAllSortItems(): void {
    this.sortItem = '';
    this.sortOrder = undefined;
    this.sortableItem = '';
    this.viewResultsOfSort.emit({ sortItem: this.sortItem, sortOrder: this.sortOrder });
  }

  viewSortResults(): void {
    this.viewResultsOfSort.emit({ sortItem: this.sortItem, sortOrder: this.sortOrder });
  }
}
