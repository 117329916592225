<div class="action-table">
  <table [class]="'action-table__table ' + class">
    <thead>
      <tr class="action-table__table-header">
        <th *ngFor="let header of headers; let i = index" class="action-table__header-item">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody class="action-table__table-body">
      <tr class="action-table__table-body-title">
        <td *ngIf="isContract">#</td>
        <td>Equipment</td>
        <td></td>
        <td>{{ getTotalEquipmentSum() | currency }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr
        *ngFor="let data of equipmentData; let i = index"
        (mouseenter)="showDelete(i)"
        (mouseleave)="hideDelete(i)"
        class="action-table__table-body-data"
      >
        <td *ngIf="isContract" class="action-table__table-body-unit-number" (click)="openModalForOfficeTrailers(i)">
          <span class="action-table__required">*</span>
<!--          <select [(ngModel)]="data.numId" disabled (click)="openModalForOfficeTrailers(i)">-->
<!--            <option value="" disabled>Select Unit Number</option>-->
<!--            <option *ngFor="let item of getNumIdsForTrailer(i)" [value]="item">-->
<!--              {{ item }}-->
<!--            </option>-->
<!--          </select>-->
          <div class="action-table__unit-number">{{data.numId ? data.numId : 'Select unit number'}}</div>
        </td>
        <td>
<!--          <select [(ngModel)]="data.description" (ngModelChange)="onChangeData()">-->
<!--            <option-->
<!--              *ngFor="let item of trailerDimensions"-->
<!--              [value]="item?.dimensionsData?.dimensions"-->
<!--            >-->
<!--              {{ item?.dimensionsData?.friendlyName }}-->
<!--            </option>-->
<!--          </select>-->
          <div class="action-table__description">
            {{data.friendlyName ? data.friendlyName : quote.trailer.dimensionsData.friendlyName}}
          </div>
        </td>
        <td>
          <input
            type="number"
            [(ngModel)]="data.quantity"
            class="action-table__table-input"
            [disabled]="isContract"
            (ngModelChange)="onChangeData()"
          />
        </td>
        <td>
          <div class="action-table__table-body-form-group">
            $
            <input
              type="number"
              [(ngModel)]="data.rate"
              class="action-table__table-input"
              (ngModelChange)="onChangeData()"
            />
          </div>
        </td>
        <td>
          <div class="action-table__table-body-form-group">
            <input
              type="number"
              [(ngModel)]="data.term"
              class="action-table__table-input"
              (ngModelChange)="onChangeData(data.term)"
            />
            month(s)
          </div>
        </td>
        <td>
          {{ getTotalEquipment(i) | currency }}
        </td>
        <i
          class="icon-minus-circle action-table__table-body-delete-icon"
          *ngIf="data.showDelete"
          (click)="openDialogToDeleteColumn(data.id, 'EQUIPMENT')"
        ></i>
      </tr>
      <tr class="action-table__table-body-actions" *ngIf="equipmentData.length < 3">
        <td *ngIf="isContract"></td>
        <td *ngIf="headers?.length" [attr.colspan]="headers.length - (isContract ? 1 : 0)">
          <div class="action-table__table-body-icon" (click)="addEquipment()">
            <i class="icon-plus-circle"></i> Add equipment
          </div>
        </td>
      </tr>
      <tr class="action-table__table-body-title" [class.action-table__d-none]="!quote?.furniture">
        <td *ngIf="isContract"></td>
        <td>Furniture</td>
        <td></td>
        <td>{{ getTotalFurnitureSum() | currency }}</td>
        <td></td>
        <td></td>
      </tr>
      <tr
        *ngFor="let data of furnitureData; let i = index"
        (mouseenter)="showFurnitureDelete(i)"
        (mouseleave)="hideFurnitureDelete(i)"
        class="action-table__table-body-data"
        [class.action-table__d-none]="!quote?.furniture"
      >
        <td *ngIf="isContract"></td>
        <td>
          <select [(ngModel)]="data.furnitureId" (ngModelChange)="onChangeData()">
            <option *ngFor="let item of furniture" [value]="item?.id">
              {{ item.description }}
            </option>
          </select>
        </td>
        <td>
          <input
            type="number"
            [(ngModel)]="data.quantity"
            class="action-table__table-input"
            (ngModelChange)="onChangeData()"
          />
        </td>
        <td>
          <div class="action-table__table-body-form-group">
            $
            <input
              type="number"
              [(ngModel)]="data.rate"
              class="action-table__table-input"
              (ngModelChange)="onChangeData()"
            />
          </div>
        </td>
        <td>
          <div class="action-table__table-body-form-group">
            <input
              type="number"
              [(ngModel)]="data.term"
              class="action-table__table-input"
              (ngModelChange)="onChangeData(data.term)"
            />
            month(s)
          </div>
        </td>
        <td>
          {{ getTotalFurniture(i) | currency }}
        </td>
        <i
          class="icon-minus-circle action-table__table-body-delete-icon"
          *ngIf="data.showDelete"
          (click)="openDialogToDeleteColumn(data.id, 'FURNITURE')"
        ></i>
      </tr>
      <tr class="action-table__table-body-actions" [class.action-table__d-none]="!quote?.furniture">
        <td *ngIf="isContract"></td>
        <td *ngIf="headers?.length" [attr.colspan]="headers.length - (isContract ? 1 : 0)">
          <div class="action-table__table-body-icon" (click)="addFurniture()">
            <i class="icon-plus-circle"></i> Add furniture
          </div>
        </td>
      </tr>
      <tr class="action-table__table-body-data">
        <td *ngIf="isContract"></td>
        <td class="action-table__table-body-td-title">
          {{ deliveryData.description }}
        </td>
        <td>
          <input
            type="number"
            class="action-table__table-input"
            [(ngModel)]="deliveryData.quantity"
            max="2"
            (ngModelChange)="onChangeData()"
          />
        </td>
        <td>
          <div class="action-table__table-body-form-group">
            $
            <input
              type="number"
              class="action-table__table-input"
              [(ngModel)]="deliveryData.rate"
              (ngModelChange)="onChangeData()"
            />
          </div>
        </td>
        <td>
          {{ deliveryData?.term }} her
        </td>
        <td>
          {{ getTotalDeliverySum() | currency }}
        </td>
      </tr>
      <tr class="action-table__table-body-sum">
        <td *ngIf="headers" [attr.colspan]="headers.length - 3"></td>
        <td colspan="2">Sub Total</td>
        <td>{{ getGeneralSubTotal() | currency }}</td>
      </tr>
      <tr class="action-table__table-body-sum">
        <td *ngIf="headers" [attr.colspan]="headers.length - 3"></td>
        <td colspan="2">GST, 5%</td>
        <td>{{ getGeneralGST() | currency }}</td>
      </tr>
      <tr class="action-table__table-body-sum action-table__table-body-sum--bold">
        <td *ngIf="headers" [attr.colspan]="headers.length - 3"></td>
        <td colspan="2">TOTAL</td>
        <td>{{ getGeneralTotal() | currency }}</td>
      </tr>
    </tbody>
  </table>
</div>
