import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-field-message-wrapper',
  templateUrl: './field-message-wrapper.component.html',
  styleUrls: ['./field-message-wrapper.component.scss'],
})
export class FieldMessageWrapperComponent {
  @Input() inputControl!: AbstractControl | null;
}
